import { selectLoading, selectAddress, selectAmountDue, selectAmountDueDate, selectCashOrCheck, selectCreditCard, selectCreditCardCode, selectCreditCardExp, selectCreditCardName, selectCreditCardNumber, selectDate, selectDateOfBirth, selectDateOfEachMonth, selectDifferentGuarantorAddress, selectDifferentGuarantorName, selectDifferentGuarantorPhone, selectDuration, selectMonthlyAmount, selectPhone, selectFirstName, selectMiddleName, selectLastName, selectDecline } from '../../../store/forms/paymentAgreement/selectors';
import { setLoading, setAddress, setAmountDue, setAmountDueDate, setCreditCardCode, setCreditCardExp, setCreditCardName, setCreditCardNumber, setDate, setDateOfBirth, setDateOfEachMonth, setDifferentGuarantorAddress, setDifferentGuarantorName, setDifferentGuarantorPhone, setDuration, setMonthlyAmount, setPhone, toggleCashOrCheck, toggleCreditCard, setFirstName, setMiddleName, setLastName, toggleDecline } from '../../../store/forms/paymentAgreement/slice';
import { getPaymentAgreement, savePaymentAgreement } from '../../../store/forms/paymentAgreement/middlewares';
import { completePaymentAgreement } from '../../../store/forms/newPatientRegistrationPacket/slice';
import { setNewActiveStep } from '../../../store/forms/newPatientRegistrationPacket/middlewares';

import { useDispatch, useSelector } from "react-redux";
import { PaymentAgreement } from './PaymentAgreement';
import { validateGroup, scrollToFunc } from '../../../utils/helper';
import { useEffect } from 'react';

export const PaymentAgreementContainer = () => {
    const dispatch = useDispatch();

    const loading = useSelector(selectLoading);

    const decline = useSelector(selectDecline);
    const firstName = useSelector(selectFirstName);
    const middleName = useSelector(selectMiddleName);
    const lastName = useSelector(selectLastName);
    const dateOfBirth = useSelector(selectDateOfBirth);
    const phone = useSelector(selectPhone);
    const address = useSelector(selectAddress);
    const amountDue = useSelector(selectAmountDue);
    const amountDueDate = useSelector(selectAmountDueDate);
    const date = useSelector(selectDate);
    const cashOrCheck = useSelector(selectCashOrCheck);
    const creditCard = useSelector(selectCreditCard);
    const monthlyAmount = useSelector(selectMonthlyAmount);
    const dateOfEachMonth = useSelector(selectDateOfEachMonth);
    const duration = useSelector(selectDuration);
    const creditCardNumber = useSelector(selectCreditCardNumber);
    const creditCardExp = useSelector(selectCreditCardExp);
    const creditCardCode = useSelector(selectCreditCardCode);
    const creditCardName = useSelector(selectCreditCardName);
    const differentGuarantorName = useSelector(selectDifferentGuarantorName);
    const differentGuarantorAddress = useSelector(selectDifferentGuarantorAddress);
    const differentGuarantorPhone = useSelector(selectDifferentGuarantorPhone);

    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(getPaymentAgreement());
    }, [dispatch]);

    const onToggleDecline = (event) => {
        dispatch(toggleDecline());
        if (event.value) {
            dispatch(savePaymentAgreement());
            dispatch(completePaymentAgreement(true));
            dispatch(setNewActiveStep());
        }
    }

    const onChangeFirstName = (event) => {
        dispatch(setFirstName(event.value.trim()));
    }

    const onChangeMiddleName = (event) => {
        dispatch(setMiddleName(event.value.trim()));
    }

    const onChangeLastName = (event) => {
        dispatch(setLastName(event.value.trim()));
    }

    const onChangeDateOfBirth = (event) => {
        dispatch(setDateOfBirth(event.value));
    }

    const onChangePhone = (event) => {
        dispatch(setPhone(event.value));
    }

    const onChangeAddress = (event) => {
        dispatch(setAddress(event.value.trim()));
    }

    const onChangeAmountDue = (event) => {
        dispatch(setAmountDue(event.value));
    }

    const onChangeAmountDueDate = (event) => {
        dispatch(setAmountDueDate(event.value));
    }

    const onChangeDate = (event) => {
        dispatch(setDate(event.value));
    }

    const onToggleCashOrCheck = () => {
        dispatch(toggleCashOrCheck());
    }

    const onToggleCreditCard = () => {
        dispatch(toggleCreditCard());
    }

    const onChangeMonthlyAmount = (event) => {
        dispatch(setMonthlyAmount(event.value));
    }

    const onChangeDateOfEachMonth = (event) => {
        dispatch(setDateOfEachMonth(event.value));
    }

    const onChangeDuration = (event) => {
        dispatch(setDuration(event.value));
    }

    const onChangeCreditCardNumber = (event) => {
        dispatch(setCreditCardNumber(event.value));
    }

    const onChangeCreditCardExp = (event) => {
        dispatch(setCreditCardExp(event.value));
    }

    const onChangeCreditCardCode = (event) => {
        dispatch(setCreditCardCode(event.value));
    }

    const onChangeCreditCardName = (event) => {
        dispatch(setCreditCardName(event.value));
    }

    const onChangeDifferentGuarantorName = (event) => {
        dispatch(setDifferentGuarantorName(event.value));
    }

    const onChangeDifferentGuarantorAddress = (event) => {
        dispatch(setDifferentGuarantorAddress(event.value));
    }

    const onChangeDifferentGuarantorPhone = (event) => {
        dispatch(setDifferentGuarantorPhone(event.value));
    }

    const onSavePaymentAgreement = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            scrollToFunc();

            dispatch(savePaymentAgreement());
            dispatch(completePaymentAgreement(true));
            dispatch(setNewActiveStep());
        }
    };

    return (
        <PaymentAgreement
            loading={loading}

            decline={decline}
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            dateOfBirth={dateOfBirth}
            phone={phone}
            address={address}
            amountDue={amountDue}
            amountDueDate={amountDueDate}
            date={date}
            cashOrCheck={cashOrCheck}
            creditCard={creditCard}
            monthlyAmount={monthlyAmount}
            dateOfEachMonth={dateOfEachMonth}
            duration={duration}
            creditCardNumber={creditCardNumber}
            creditCardExp={creditCardExp}
            creditCardCode={creditCardCode}
            creditCardName={creditCardName}
            differentGuarantorName={differentGuarantorName}
            differentGuarantorAddress={differentGuarantorAddress}
            differentGuarantorPhone={differentGuarantorPhone}

            onToggleDecline={onToggleDecline}
            onChangeFirstName={onChangeFirstName}
            onChangeMiddleName={onChangeMiddleName}
            onChangeLastName={onChangeLastName}
            onChangeDateOfBirth={onChangeDateOfBirth}
            onChangePhone={onChangePhone}
            onChangeAddress={onChangeAddress}
            onChangeAmountDue={onChangeAmountDue}
            onChangeAmountDueDate={onChangeAmountDueDate}
            onChangeDate={onChangeDate}
            onToggleCashOrCheck={onToggleCashOrCheck}
            onToggleCreditCard={onToggleCreditCard}
            onChangeMonthlyAmount={onChangeMonthlyAmount}
            onChangeDateOfEachMonth={onChangeDateOfEachMonth}
            onChangeDuration={onChangeDuration}
            onChangeCreditCardNumber={onChangeCreditCardNumber}
            onChangeCreditCardExp={onChangeCreditCardExp}
            onChangeCreditCardCode={onChangeCreditCardCode}
            onChangeCreditCardName={onChangeCreditCardName}
            onChangeDifferentGuarantorName={onChangeDifferentGuarantorName}
            onChangeDifferentGuarantorAddress={onChangeDifferentGuarantorAddress}
            onChangeDifferentGuarantorPhone={onChangeDifferentGuarantorPhone}

            onSavePaymentAgreement={onSavePaymentAgreement}
        />
    )
}