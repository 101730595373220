import { FETCH_STATUSES } from "../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";
import { postForgotPassword } from "./middlewares";

const initialState = {
    email: "",
    status: FETCH_STATUSES.IDLE,
    error: null,
    btnStatus: false,
};
export const forgotPassword = createSlice({
    name: "forgotPassword",
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postForgotPassword.pending, (state) => {
                state.status = FETCH_STATUSES.REQUEST;
                state.error = null;
                state.btnStatus = true;
            })
            .addCase(postForgotPassword.fulfilled, (state) => {
                state.status = FETCH_STATUSES.SUCCESS;
                state.error = null;
                state.btnStatus = false;
            })
            .addCase(postForgotPassword.rejected, (state, action) => {
                state.status = FETCH_STATUSES.FAILURE;
                state.error = Object.values(action.payload.errors) ?? ['Server error'];
                state.btnStatus = false;
            });
    },
});

export const {
    setEmail,
} = forgotPassword.actions;

export default forgotPassword.reducer;
