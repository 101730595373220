import { MAX_WIDTH, MAX_CURRENT_DATE, MIN_CURRENT_DATE, CONSENT, GRID_XS } from '../../../../../utils/constants';
import { Signature } from '../../../../../components/Signature/Signature.style';
import { NAME_RULE } from '../../../../../utils/rules';
import { Wrapper, Text } from '../../../../../components/Main/Main.style';
import { dateFormatFromApi } from '../../../../../utils/formats';
import SignatureCanvas from 'react-signature-canvas';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	PatternRule,
	RangeRule,
} from 'devextreme-react/validator';

export const Consent = ({
	loading,
	error,

	name,
	signed,
	guardian,
	signedDate,
	guardianDate,

	onChangeName,
	onChangeSigned,
	onChangeGuardian,
	onChangeSignedDate,
	onChangeGuardianDate,

	onInitSignature,
	onChangeSignature,
	onClearSignature,
	onSaveConsent,
	onSaveAndContinueConsent,
}) => {
	return (
		<Wrapper id={CONSENT} maxWidth={MAX_WIDTH} >
			<LoadPanel
				visible={loading}
				position={{ of: `#${CONSENT}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<Text>
							<b>Consent: </b>
							I hereby certify that all information provided is true and correct to the best of my knowledge and I give
							my consent for APIA to administer assessments and/or treatments to perform behavioral health or medical
							services. APIA operates as an integrated health system and may refer patients as necessary.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Printed Name *">
							<>
								<TextBox
									label="Printed Name *"
									value={name}
									onValueChanged={onChangeName}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-name' }}
								>
									<Validator validationGroup={CONSENT}>
										<RequiredRule
											message="Printed Name is required"
										/>
										<PatternRule
											message="Do not use digits in the Printed Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							I authorize APIA Behavioral Health Providers to disclose general
							mental health, housing, drug or alcohol use information to APIA staff for the purpose of program evaluation and
							grant reporting. I understand I may be contacted to complete a questionnaire over the telephone or by email. I
							understand that I may revoke this authorization at any time preventing future use. Unless I revoke my consent
							earlier, this consent will expire automatically one year from active services at APIA.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							<b>Assignment of Medical Insurance Benefits: </b>
							I hereby agree to full responsibility for all expenses incurred by or
							on account of this patient and assign to APIA, Inc. any and all insurance benefits due to me to the full extent of
							my financial obligation and said providers. This is certification and authorization is valid until otherwise revoking
							in writing.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							I consent to full financial responsibility for services rendered at APIA, Inc.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS} sm={8}>
						<Tooltip title="Signed *">
							<>
								<TextBox
									label="Signed *"
									value={signed}
									onValueChanged={onChangeSigned}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-signed' }}
								>
									<Validator validationGroup={CONSENT} >
										<RequiredRule
											message="Signed is required"
										/>
										<PatternRule
											message="Do not use digits in the Signed"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={4}>
						<Tooltip title="Date *">
							<>
								<DateBox
									type="date"
									label="Date *"
									value={dateFormatFromApi(signedDate)}
									onValueChanged={onChangeSignedDate}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={CONSENT} >
										<RequiredRule
											message="Date is required"
										/>
										<RangeRule
											message="Date cannot be earlier or later than that year"
											min={MIN_CURRENT_DATE}
											max={MAX_CURRENT_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={8}>
						<Tooltip title="Guardian">
							<>
								<TextBox
									label="Guardian"
									value={guardian}
									onValueChanged={onChangeGuardian}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-guardian' }}
								>
									<Validator validationGroup={CONSENT}>
										<PatternRule
											message="Do not use digits in the Printed Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={4}>
						<Tooltip title="Date">
							<>
								<DateBox
									type="date"
									label="Date"
									value={dateFormatFromApi(guardianDate)}
									onValueChanged={onChangeGuardianDate}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={CONSENT}>
										<RangeRule
											message="Date cannot be earlier or later than that year"
											min={MIN_CURRENT_DATE}
											max={MAX_CURRENT_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Signature error={error}>
							<legend>Signature *</legend>
							<SignatureCanvas
								ref={onInitSignature}
								onEnd={onChangeSignature}
								clearOnResize={false}
								canvasProps={{
									id: 'responsive-canvas'
								}}
							/>
						</Signature>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right', gap: '10px', display: 'flex', justifyContent: 'flex-end' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="outlined"
							validationGroup={CONSENT}
							onClick={onSaveConsent} />
						<Button
							text="Clear Signature"
							type="default"
							stylingMode="outlined"
							onClick={onClearSignature} />
						<Button
							text="Save and continue"
							type="default"
							stylingMode="contained"
							validationGroup={CONSENT}
							onClick={onSaveAndContinueConsent} />
					</Grid>
				</Grid>
			}
		</Wrapper >
	);
};