import moment from "moment";

export const dateFormatForApi = (date) => !!date ? moment(date).format() : null;

export const dateFormatFromApi = (selectDate) => {
    if (!!selectDate) {
        const date = new Date(selectDate);
        const calcDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 18, 0, 0, 0);
        return calcDate;
    }
    else {
        return null;
    }
}