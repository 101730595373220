let timeOut = null;

export const onFocusInTextboxMask = ({ element }) => {
  timeOut = setTimeout(() => {
    const isUndefinedRequired = !element || !element.children[1]
      || !element.children[1].children[0]
      || !element.children[1].children[0].children[0]
      || !element.children[1].children[0].children[0].selectionStart
      || !element.children[1].children[0].children[0].selectionEnd;

    const isUndefinedNotRequired = !element || !element.children[0]
      || !element.children[0].children[0]
      || !element.children[0].children[0].children[0]
      || !element.children[0].children[0].children[0].selectionStart
      || !element.children[0].children[0].children[0].selectionEnd;

    if (!isUndefinedRequired) {
      element.children[1].children[0].children[0].selectionStart = 0;
      element.children[1].children[0].children[0].selectionEnd = 0;
    }

    if (!isUndefinedNotRequired) {
      element.children[0].children[0].children[0].selectionStart = 0;
      element.children[0].children[0].children[0].selectionEnd = 0;
    }
  }, 100);
}

export const onFocusOutTextboxMask = () => {
  clearTimeout(timeOut);
}

/**
 * 
 * @param {*}
 */
export const onKeyDownHandle = ({ event }) => {
  const allowedChars = ['space'];
  if (allowedChars.includes(event?.code?.toLowerCase())) event.preventDefault();
};

/**
 *
 * @param {*} bytes
 * @param {*} decimals
 * @returns {string}
 * @example 4 MB
 */
export const formatBytes = (bytes = 4000000, decimals = 0) => {
  if (bytes === 0) return '0';
  else {
    let k = 1024;
    let dm = decimals < 0 ? 0 : decimals;
    let sizes = ['byte', 'kB', 'MB', 'GB', 'TB'];
    let i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
};

/**
 * Group validation fuction 
 * @param {*} event button submit event
 * @returns {boolean} is valid value
 */
export const validateGroup = (event) => {
  const isValidationGroup = event.validationGroup !== undefined;

  if (isValidationGroup) {
    const result = event.validationGroup.validate();
    const isValid = result.isValid;

    if (!isValid) {
      result.brokenRules[0].validator.focus();
    };

    console.log('result', result)

    return isValid;
  };

  return true;
}

/**
 * 
 * @param {*} blob 
 * @param {*} fileName 
 */
export const downloadFile = (blob, fileName) => {
  let a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

/**
 * 
 * @param {*} formName
 * @returns
 */
export const isDisabledFromDownload = (formName = []) => {
  const disabledFormNames = ['InsuranceCardAndId', 'AnnualPatientForm'];
  return disabledFormNames.includes(formName);
};

/**
 * 
 * @param {number} top
 * @param {number} left
 * @param {string} behavior 'smooth' | 'instant' | 'auto'
 * @returns {undefined}
 */
export const scrollToFunc = (top = 0, left = 0, behavior = 'smooth') => {
  return window.scrollTo({
    top,
    left,
    behavior,
  });
};