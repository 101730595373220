import { Wrapper, Item, Label, TextBox, SelectBox, Buttons } from '../../BasicInformation.style';
import { MAX_WIDTH, NEXT_KIN, PHONE_MASK, ZIP_MASK, GRID_XS } from '../../../../../../utils/constants';
import { onFocusInTextboxMask, onFocusOutTextboxMask, onKeyDownHandle } from '../../../../../../utils/helper';
import { NAME_RULE } from '../../../../../../utils/rules';
import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	EmailRule,
	PatternRule,
} from 'devextreme-react/validator';

const setSizeByWidth = (width) => {
	switch (true) {
		case (width <= 600): return 12;
		case (width > 600 && width <= 900): return 6;
		case (width > 900 && width <= 1200): return 4;
		case (width > 1200 && width <= 1500): return 3;
		case (width > 1500): return 2;
		default: return 12;
	};
};

export const NextOfKin = ({
	showButton,

	states,

	getEntityPatientProfileNextOfKin,
	onChangeFunc,

	onCancelNextOfKin,
	onSaveNextOfKin,
}) => {
	const { width, ref } = useResizeDetector();
	const [size, setSize] = useState(12);

	useEffect(() => {
		setSize(setSizeByWidth(width));
	}, [width]);

	return (
		<Wrapper id={NEXT_KIN} ref={ref} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileNextOfKin('loading')}
				position={{ of: `#${NEXT_KIN}` }} />
			{
				!getEntityPatientProfileNextOfKin('loading') &&
				<Grid container spacing={2}>
					<Item item xs={size}>
						<Tooltip title="Next of Kin *">
							<span>
								<Label>Next of Kin:</Label>
								<TextBox
									label="Next of Kin *"
									value={getEntityPatientProfileNextOfKin('nextOfKin')}
									onValueChanged={(e) => onChangeFunc('NextOfKin', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-nextOfKin' }}
								>
									<Validator validationGroup={NEXT_KIN}>
										<RequiredRule
											message="Next of Kin is required"
										/>
										<PatternRule
											message="Do not use digits in the Next of Kin"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Relationship">
							<span>
								<Label>Relationship:</Label>
								<TextBox
									label="Relationship"
									value={getEntityPatientProfileNextOfKin('relationship')}
									onValueChanged={(e) => onChangeFunc('Relationship', e, true)}
									maxLength={30}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-relationship' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Address">
							<span>
								<Label>Address:</Label>
								<TextBox
									label="Address"
									value={getEntityPatientProfileNextOfKin('address')}
									onValueChanged={(e) => onChangeFunc('Address', e, true)}
									maxLength={150}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="State">
							<span>
								<Label>State:</Label>
								<SelectBox
									label="State"
									defaultValue={getEntityPatientProfileNextOfKin('state')}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={(e) => onChangeFunc('State', e)}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-state' }}
								/>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="City">
							<span>
								<Label>City:</Label>
								<TextBox
									label="City"
									value={getEntityPatientProfileNextOfKin('city')}
									onValueChanged={(e) => onChangeFunc('City', e, true)}
									maxLength={20}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-city' }}>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Zip">
							<span>
								<Label>Zip:</Label>
								<TextBox
									label="Zip"
									value={getEntityPatientProfileNextOfKin('zip')}
									onValueChanged={(e) => onChangeFunc('Zip', e)}
									labelMode="hidden"
									stylingMode="underlined"
									onKeyDown={onKeyDownHandle}
									onFocusIn={onFocusInTextboxMask}
									onFocusOut={onFocusOutTextboxMask}
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-zip' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Phone *">
							<span>
								<Label>Phone:</Label>
								<TextBox
									label="Phone *"
									value={getEntityPatientProfileNextOfKin('phone')}
									onValueChanged={(e) => onChangeFunc('Phone', e)}
									labelMode="hidden"
									stylingMode="underlined"
									onKeyDown={onKeyDownHandle}
									onFocusIn={onFocusInTextboxMask}
									onFocusOut={onFocusOutTextboxMask}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }}>
									<Validator validationGroup={NEXT_KIN}>
										<RequiredRule
											message="Phone is required"
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Email">
							<span>
								<Label>Email:</Label>
								<TextBox
									label="Email"
									value={getEntityPatientProfileNextOfKin('email')}
									onValueChanged={(e) => onChangeFunc('Email', e, true)}
									maxLength={64}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-email' }}>
									<Validator validationGroup={NEXT_KIN}>
										<EmailRule
											message="Email is invalid"
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={NEXT_KIN}
								onClick={onSaveNextOfKin} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelNextOfKin} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper >
	);
};