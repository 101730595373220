import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiDrawer from '@mui/material/Drawer';
import img from '../../../images/nav.png';
import { styled } from '@mui/material/styles';

const drawerWidth = 300;

const openedMixin = (theme) => ({
    height: '100vh',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    '@media (max-width: 660px)': {
        width: '100vw',
    },
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    '@media (max-width: 660px)': {
        width: '0px',
    },
});

export const ItemButton = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'active'
})(({ open, active }) => ({
    minHeight: '48px',
    margin: '8px 16px',
    padding: '0 10px',
    justifyContent: open ? 'initial' : 'center',
    ...(active && {
        borderRadius: '10px',
        backgroundColor: open ? 'var(--active-color)' : 'var(--white-color)',
    }),
    '&:hover': {
        borderRadius: '10px',
    },
}));

export const ItemText = styled(ListItemText, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'active'
})(({ open, active }) => ({
    opacity: open ? 1 : 0,
    '& span': {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '17px',
        textTransform: 'uppercase',
        color: 'var(--black-blue-color)',
        ...(active && {
            color: 'var(--light-blue-color)',
        }),
    },
}));

export const ItemIcon = styled(ListItemIcon, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'active'
})(({ open, active }) => ({
    color: 'var(--black-blue-color)',
    marginRight: open ? '20px' : 'auto',
    minWidth: 0,
    justifyContent: 'center',
    ...(active && {
        color: 'var(--light-blue-color)',
    }),
}));

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        height: '100vh',
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));


export const DrawerBottom = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ open }) => ({
        marginTop: 'auto',
        display: 'flex',
        flexDirection: 'column',
        ...(open && {
            position: 'relative',
            height: '500px',
            width: { drawerWidth },
            '&::before': {
                background: `no-repeat url(${img})`,
                position: 'absolute',
                content: '""',
                opacity: '0.1',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        }),
        '@media (max-width: 660px)': {
            marginTop: 'none',
            ...(open && {
                height: '100%',
                '&::before': {
                    backgroundSize: 'cover',
                },
            }),
        },
    }),
);

