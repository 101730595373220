import { isAdmin } from '../../../core/auth/authProvider';
import { MAX_CURRENT_DATE, MAX_DATE, MAX_WIDTH, MIN_CURRENT_DATE, MIN_DATE, fileExtensions, maxFileSize, BASE_URL, SSN_MASK, PHONE_MASK, ZIP_MASK, GRID_XS, GRID_SM, GRID_MD } from '../../../utils/constants';
import { FileUploaderValidation } from '../../../components/FileUploaderValidation/FileUploaderValidation';
import { Wrapper, TitleWrapper, Title, Text, Fieldset, OrderedList, Item, CheckboxGrid, Buttons } from '../../../components/Main/Main.style';
import DataGrid, { Column, Editing, Toolbar, Item as ItemToolbar } from 'devextreme-react/data-grid';
import { onFocusInTextboxMask, onFocusOutTextboxMask, onKeyDownHandle } from '../../../utils/helper';
import { Signature } from '../../../components/Signature/Signature.style';
import { CITY_RULE, NAME_RULE } from '../../../utils/rules';
import { dateFormatFromApi } from '../../../utils/formats';
import Link from '../../../components/Link/Link.style';
import SignatureCanvas from 'react-signature-canvas';
import { Grid } from '@mui/material';
import { useState } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import LoadPanel from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import NumberBox from 'devextreme-react/number-box';
import CheckBox from 'devextreme-react/check-box';
import DateBox from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import TagBox from 'devextreme-react/tag-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	PatternRule,
	RangeRule,
	CustomRule,
} from 'devextreme-react/validator';

export const DiscountSchedule = ({
	loading,
	error,

	states,
	employments,
	proofOfIncomes,
	verifications,
	applicantMayQualifies,
	discounts,
	answers,

	decline,
	firstName,
	middleName,
	lastName,
	SSN,
	date,
	phone,
	dateOfBirth,
	address,
	city,
	state,
	zip,
	employment,
	employer,
	workPhone,
	months,
	workedOnBoat,
	workedOnBoatContact,
	proofOfIncome,
	otherProofOfIncome,
	fileProofOfIncome,
	totalIncome,
	household,
	healthInsurance,
	privateHealthInsurance,
	medicare,
	medicaid,
	applied,
	effectiveDate,
	whoInsurance,
	dateOfBirthInsured,
	frontCard,
	backCard,
	verification,
	otherVerification,
	applicantMayQualify,
	eligibilityDate,
	renewalDate,
	discount,
	verifiedBy,
	verifiedDate,

	onToggleDecline,
	onChangeFirstName,
	onChangeMiddleName,
	onChangeLastName,
	onChangeDate,
	onChangeSSN,
	onChangeDateOfBirth,
	onChangePhone,
	onChangeAddress,
	onChangeCity,
	onChangeState,
	onChangeZip,
	onChangeEmployment,
	onChangeEmployer,
	onChangeWorkPhone,
	onChangeMonths,
	onToggleWorkedOnBoat,
	onChangeWorkedOnBoatContact,
	onChangeProofOfIncome,
	onChangeOtherProofOfIncome,
	onSelectFileProofOfIncome,
	onChangeTotalIncome,
	onRowInsertingHousehold,
	onRowRemovingHousehold,
	onRowUpdatingHousehold,
	onChangeHealthInsurance,
	onChangePrivateHealthInsurance,
	onChangeMedicare,
	onChangeMedicaid,
	onChangeApplied,
	onChangeEffectiveDate,
	onChangeWhoInsurance,
	onChangeDateOfBirthInsured,
	onSelectFrontCard,
	onSelectBackCard,
	onChangeVerification,
	onChangeOtherVerification,
	onChangeApplicantMayQualify,
	onChangeEligibilityDate,
	onChangeRenewalDate,
	onChangeDiscount,
	onChangeVerifiedBy,
	onChangeVerifiedDate,

	onInitSignature,
	onChangeSignature,
	onClearSignature,

	onClickAttachedFile,
	onSaveDiscountSchedule,
	onClickBack,
}) => {
	const [chunks, setChunks] = useState([]);
	const sectionName = "discountSchedule";

	const onUploadProgress = (e) => {
		const chunk = {
			segmentSize: e.segmentSize,
			bytesLoaded: e.bytesLoaded,
			bytesTotal: e.bytesTotal,
		};
		setChunks(chunk);
		return chunks;
	}

	const onUploadStarted = () => {
		setChunks([]);
	}

	const checkSelectRequired = ({ value }) => {
		return value !== null;
	}

	const _token = JSON.parse(localStorage.getItem('AUTH_TOKEN_DATA'));

	return (
		<Wrapper id={sectionName} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${sectionName}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<TitleWrapper>
							{isAdmin() && <Button hint='Go back to forms' icon="back" onClick={onClickBack} />}
							<Tooltip title="Sliding Fee/Discount Schedule Application">
								<Title>Sliding Fee/Discount Schedule Application</Title>
							</Tooltip>
						</TitleWrapper>
					</Grid>
					<CheckboxGrid container item spacing={2}>
						<CheckboxGrid item>
							<Tooltip title="This form is optional. Check this checkbox if you DON’T WANT to see if you may qualify for a reduced rate based on income.">
								<Text>This form is <span style={{ color: 'var(--green-text-color)' }}>optional</span>. Check this checkbox if you DON’T WANT to see if you may qualify for a reduced rate based on income.</Text>
							</Tooltip>
						</CheckboxGrid>
						<CheckboxGrid item>
							<CheckBox
								value={decline}
								onValueChanged={onToggleDecline} />
						</CheckboxGrid>
					</CheckboxGrid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="First Name *">
							<>
								<TextBox
									label="First Name *"
									value={firstName}
									onValueChanged={onChangeFirstName}
									disabled={decline}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-firstName' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="First Name is required"
										/>
										<PatternRule
											message="Do not use digits in the First Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Middle Name">
							<>
								<TextBox
									label="Middle Name"
									value={middleName}
									onValueChanged={onChangeMiddleName}
									disabled={decline}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-middleName' }}
								>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Last Name *">
							<>
								<TextBox
									label="Last Name *"
									value={lastName}
									onValueChanged={onChangeLastName}
									disabled={decline}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-lastName' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Last Name is required"
										/>
										<PatternRule
											message="Do not use digits in the Last Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="SSN *">
							<>
								<TextBox
									label="SSN *"
									value={SSN}
									onValueChanged={onChangeSSN}
									disabled={decline}
									labelMode="floating"
									stylingMode="outlined"
									maskChar="."
									onKeyDown={onKeyDownHandle}
									onFocusIn={onFocusInTextboxMask}
									onFocusOut={onFocusOutTextboxMask}
									mask={SSN_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="SSN must be in the correct format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-ssn' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="SSN is required"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Date of Birth">
							<>
								<DateBox
									label="Date of Birth"
									type="date"
									value={dateFormatFromApi(dateOfBirth)}
									onValueChanged={onChangeDateOfBirth}
									disabled={decline}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Phone">
							<>
								<TextBox
									label="Phone"
									value={phone}
									onValueChanged={onChangePhone}
									disabled={decline}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									onFocusIn={onFocusInTextboxMask}
									onFocusOut={onFocusOutTextboxMask}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Address">
							<>
								<TextBox
									label="Address"
									value={address}
									onValueChanged={onChangeAddress}
									disabled={decline}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="City">
							<>
								<TextBox
									label="City"
									value={city}
									onValueChanged={onChangeCity}
									disabled={decline}
									maxLength={20}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-city' }}>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="City is required"
										/>
										<PatternRule
											message="Do not use digits in the City"
											pattern={CITY_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="State">
							<>
								<SelectBox
									label="State"
									defaultValue={state}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={onChangeState}
									disabled={decline}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-state' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Zip">
							<>
								<TextBox
									label="Zip"
									value={zip}
									onValueChanged={onChangeZip}
									disabled={decline}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									onFocusIn={onFocusInTextboxMask}
									onFocusOut={onFocusOutTextboxMask}
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-zip' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_XS}>
						<Tooltip title="Employment Status">
							<>
								<SelectBox
									label="Employment Status"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={employment}
									dataSource={employments}
									onValueChanged={onChangeEmployment}
									disabled={decline}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									showDropDownButton={true}
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-employment' }}
								/>
							</>
						</Tooltip>
					</Grid>
					{
						employment === (employments?.find((e) => e?.Name === "Full Time" || e?.Name === "Part Time")?.Oid) &&
						<>
							<Grid item xs={GRID_XS} sm={GRID_SM}>
								<Tooltip title="Employers Name">
									<>
										<TextBox
											label="Employers Name"
											value={employer}
											onValueChanged={onChangeEmployer}
											disabled={decline}
											maxLength={30}
											labelMode="floating"
											stylingMode="outlined"
											inputAttr={{ autocomplete: 'new-employer' }} />
									</>
								</Tooltip>
							</Grid>
							<Grid item xs={GRID_XS} sm={GRID_SM}>
								<Tooltip title="Work Phone">
									<>
										<TextBox
											label="Work Phone"
											value={workPhone}
											onValueChanged={onChangeWorkPhone}
											disabled={decline}
											labelMode="floating"
											stylingMode="outlined"
											onKeyDown={onKeyDownHandle} s
											onFocusIn={onFocusInTextboxMask}
											onFocusOut={onFocusOutTextboxMask}
											maskChar="."
											mask={PHONE_MASK}
											showMaskMode="onFocus"
											maskRules={{ X: /[0-9]/ }}
											maskInvalidMessage="The phone must have a correct USA phone format"
											validationMessagePosition="bottom"
											inputAttr={{ autocomplete: 'new-workPhone' }} />
									</>
								</Tooltip>
							</Grid>
						</>
					}
					{
						employment === (employments?.find((e) => e?.Name === "Subsistence or Seasonal")?.Oid) &&
						<Grid item xs={GRID_XS}>
							<Tooltip title="How many months?">
								<>
									<NumberBox
										label="How many months?"
										value={months}
										onValueChanged={onChangeMonths}
										disabled={decline}
										showSpinButtons={true}
										min={0}
										labelMode="floating"
										stylingMode="outlined" />
								</>
							</Tooltip>
						</Grid>
					}
					<Grid item xs={GRID_XS}>
						<Tooltip title="Have you worked on a boat/fishing vessel within the past year?">
							<>
								<CheckBox
									text="Have you worked on a boat/fishing vessel within the past year?"
									value={workedOnBoat}
									onValueChanged={onToggleWorkedOnBoat}
									disabled={decline} />
							</>
						</Tooltip>
					</Grid>
					{
						workedOnBoat &&
						<Grid item xs={GRID_XS}>
							<Tooltip title="If yes, please provide boat’s name, captain’s name, address and telephone number">
								<>
									<TextBox
										label="If yes, please provide boat’s name, captain’s name, address and telephone number"
										value={workedOnBoatContact}
										onValueChanged={onChangeWorkedOnBoatContact}
										disabled={decline}
										maxLength={30}
										labelMode="floating"
										stylingMode="outlined"
										inputAttr={{ autocomplete: 'new-workedOnBoatContact' }} />
								</>
							</Tooltip>
						</Grid>
					}
					<Grid item xs={GRID_XS}>
						<Tooltip title="Type of proof of income *">
							<>
								<SelectBox
									label="Type of proof of income *"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={proofOfIncome}
									dataSource={proofOfIncomes}
									onValueChanged={onChangeProofOfIncome}
									disabled={decline}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-proofOfIncome' }}>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Type of proof of income is required"
										/>
										<RangeRule
											message="Type of proof of income is required"
											min={0} />
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					{
						proofOfIncome === (proofOfIncomes?.find((e) => e?.Name === "Other")?.Oid) &&
						<Grid item xs={GRID_XS}>
							<Tooltip title="Other type of proof of income">
								<>
									<TextBox
										label="Other type of proof of income"
										value={otherProofOfIncome}
										onValueChanged={onChangeOtherProofOfIncome}
										disabled={decline}
										maxLength={30}
										labelMode="floating"
										stylingMode="outlined"
										inputAttr={{ autocomplete: 'new-otherProofOfIncome' }} />
								</>
							</Tooltip>
						</Grid>
					}
					<Grid item xs={GRID_XS}>
						<Fieldset disabled={decline}>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><b>Proof of Income:</b></Text>
									{
										fileProofOfIncome &&
										<Text style={{ padding: '10px 10px 0px' }}>
											Attached:
											<Link onClick={() => onClickAttachedFile(fileProofOfIncome.id, fileProofOfIncome.fileName)}> {fileProofOfIncome.fileName}</Link>
										</Text>
									}
									<FileUploaderValidation fileExtensions={fileExtensions} maxFileSize={maxFileSize} />
									<FileUploader
										defaultValue={[new File([], fileProofOfIncome.fileName, { type: 'image/*' })]}
										maxFileSize={maxFileSize}
										chunkSize={190000}
										multiple={false}
										uploadMode={'instantly'}
										accept={fileExtensions.join(', ')}
										allowedFileExtensions={fileExtensions}
										uploadUrl={BASE_URL + "/FileUploader/uploadProofOfIncomeDocument"}
										onUploaded={onSelectFileProofOfIncome}
										onProgress={onUploadProgress}
										onUploadStarted={onUploadStarted}
										uploadHeaders={
											{ 'Authorization': 'Bearer ' + _token.accessToken }
										}>
										<Validator validationGroup={sectionName}>
											<RequiredRule
												message="This field is required"
											/>
										</Validator>
									</FileUploader>
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Annual Income">
							<>
								<NumberBox
									label="Annual Income"
									value={totalIncome}
									onValueChanged={onChangeTotalIncome}
									disabled={decline}
									showSpinButtons={true}
									min={0}
									labelMode="floating"
									stylingMode="outlined">
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Annual Income is required"
										/>
									</Validator>
								</NumberBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={decline} style={{ paddingBottom: '8px' }}>
							Please list ALL members (people) in the household (spouse, child, foster child, grandparent, aunt, uncle, etc.).
						</Text>
						<OrderedList disabled={decline}>
							<Item>To add a person, click on the "Add a Row" button and start typing.</Item>
							<Item>To save your entry, click on the floppy disk icon at the end of a row or press "Enter".</Item>
							<Item>Once saved, you can come back and edit/delete an entry by clicking on the corresponding icon at the end of a row.</Item>
							<Item>Repeat steps 1 and 2 to add more people.</Item>
						</OrderedList>
					</Grid>
					<Grid item xs={GRID_XS}>
						<DataGrid
							dataSource={household}
							showBorders={true}
							disabled={decline}
							onRowInserting={onRowInsertingHousehold}
							onRowRemoving={onRowRemovingHousehold}
							onRowUpdating={onRowUpdatingHousehold}>
							<Toolbar>
								<ItemToolbar location="before" name='addRowButton' showText />
							</Toolbar>
							<Editing
								mode="row"
								allowAdding={true}
								allowUpdating={true}
								allowDeleting={true}
								confirmDelete={false} />
							<Column
								dataField="name"
								caption="Full Name"
								alignment="left" >
								<RequiredRule />
							</Column>
							<Column
								dataField="relationship"
								caption="Relationship"
								alignment="left" />
							<Column
								dataField="dateOfBirth"
								caption="Date Of Birth"
								dataType="date"
								alignment="left"
								editorOptions={{
									useMaskBehavior: true
								}} />
							<Column
								dataField="income"
								caption="Income"
								dataType="number"
								alignment="left" >
								<RequiredRule />
								<CustomRule
									message="This field must be greater zero"
									validationCallback={({ data }) => data?.income <= 0 ? false : true}
								/>
							</Column>
						</DataGrid>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Do you have health insurance? *">
							<>
								<SelectBox
									label="Do you have health insurance? *"
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={answers}
									defaultValue={healthInsurance}
									onValueChanged={onChangeHealthInsurance}
									disabled={decline}
									className="label__medium"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-healthInsurance' }}>
									<Validator validationGroup={sectionName}>
										<CustomRule
											message="This field is required"
											validationCallback={checkSelectRequired}
										/>
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Do you have Private Health Insurance? *">
							<>
								<SelectBox
									label="Do you have Private Health Insurance? *"
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={answers}
									defaultValue={privateHealthInsurance}
									onValueChanged={onChangePrivateHealthInsurance}
									disabled={decline}
									className="label__medium"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-privateHealthInsurance' }}>
									<Validator validationGroup={sectionName}>
										<CustomRule
											message="This field is required"
											validationCallback={checkSelectRequired}
										/>
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Do you have Medicare? *">
							<>
								<SelectBox
									label="Do you have Medicare? *"
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={answers}
									defaultValue={medicare}
									onValueChanged={onChangeMedicare}
									disabled={decline}
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-medicare' }} >
									<Validator validationGroup={sectionName}>
										<CustomRule
											message="This field is required"
											validationCallback={checkSelectRequired}
										/>
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Do you have Medicaid or Denali Kid Care? *">
							<>
								<SelectBox
									label="Do you have Medicaid or Denali Kid Care? *"
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={answers}
									defaultValue={medicaid}
									onValueChanged={onChangeMedicaid}
									disabled={decline}
									className="label__medium"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-medicaid' }}>
									<Validator validationGroup={sectionName}>
										<CustomRule
											message="This field is required"
											validationCallback={checkSelectRequired}
										/>
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Have you ever applied for either? *">
							<>
								<SelectBox
									label="Have you ever applied for either? *"
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={answers}
									defaultValue={applied}
									onValueChanged={onChangeApplied}
									disabled={decline}
									className="label__medium"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-applied' }}>
									<Validator validationGroup={sectionName}>
										<CustomRule
											message="This field is required"
											validationCallback={checkSelectRequired}
										/>
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					{
						(healthInsurance === (answers.find((e) => e?.Name === "Yes")?.Oid)
							|| privateHealthInsurance === (answers.find((e) => e?.Name === "Yes")?.Oid)
							|| medicare === (answers.find((e) => e?.Name === "Yes")?.Oid)
							|| medicaid === (answers.find((e) => e?.Name === "Yes")?.Oid)) &&
						<>
							<Grid item xs={GRID_XS} md={GRID_MD}>
								<Tooltip title="Who is the insurance under">
									<>
										<TextBox
											label="Who is the insurance under"
											value={whoInsurance}
											onValueChanged={onChangeWhoInsurance}
											disabled={decline}
											maxLength={30}
											labelMode="floating"
											stylingMode="outlined"
											inputAttr={{ autocomplete: 'new-whoInsurance' }} />
									</>
								</Tooltip>
							</Grid>
							<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
								<Tooltip title="Effective date">
									<>
										<DateBox
											label="Effective date"
											type="date"
											value={dateFormatFromApi(effectiveDate)}
											onValueChanged={onChangeEffectiveDate}
											disabled={decline}
											useMaskBehavior={true}
											displayFormat="MM/dd/yyyy"
											invalidDateMessage="The date must have the following format: MM/dd/yyyy"
											labelMode="floating"
											stylingMode="outlined"
										>
											<Validator validationGroup={sectionName}>
												<RangeRule
													message="Date cannot be earlier than 1900 and later than 2100"
													min={MIN_DATE}
													max={MAX_DATE} />
											</Validator>
										</DateBox>
									</>
								</Tooltip>
							</Grid>
							<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
								<Tooltip title="Date of birth of insured">
									<>
										<DateBox
											label="Date of birth of insured"
											type="date"
											value={dateFormatFromApi(dateOfBirthInsured)}
											onValueChanged={onChangeDateOfBirthInsured}
											disabled={decline}
											useMaskBehavior={true}
											displayFormat="MM/dd/yyyy"
											invalidDateMessage="The date must have the following format: MM/dd/yyyy"
											labelMode="floating"
											stylingMode="outlined"
										>
										</DateBox>
									</>
								</Tooltip>
							</Grid>
							<Grid item xs={GRID_XS}>
								<Fieldset disabled={decline}>
									<Grid container spacing={2}>
										<Grid item xs={GRID_XS}>
											<Text><b>Front of Insurance Card</b></Text>
											{
												frontCard &&
												<Text style={{ padding: '10px 10px 0px' }}>
													Attached:
													<Link onClick={() => onClickAttachedFile(frontCard.id, frontCard.fileName)}> {frontCard.fileName}</Link>
												</Text>
											}
											<FileUploader
												maxFileSize={maxFileSize}
												chunkSize={190000}
												multiple={false}
												uploadMode={'instantly'}
												accept={'*'}
												allowedFileExtensions={fileExtensions}
												uploadUrl={BASE_URL + "/FileUploader/uploadFrontOfInsuranceCard"}
												onUploaded={onSelectFrontCard}
												onProgress={onUploadProgress}
												onUploadStarted={onUploadStarted}
												uploadHeaders={
													{ 'Authorization': 'Bearer ' + _token.accessToken }
												}
											/>
											<FileUploaderValidation fileExtensions={fileExtensions} maxFileSize={maxFileSize} />
										</Grid>
									</Grid>
								</Fieldset>
							</Grid>
							<Grid item xs={GRID_XS}>
								<Fieldset disabled={decline}>
									<Grid container spacing={2}>
										<Grid item xs={GRID_XS}>
											<Text><b>Back of Insurance Card</b></Text>
											{
												backCard &&
												<Text style={{ padding: '10px 10px 0px' }}>
													Attached:
													<Link onClick={() => onClickAttachedFile(backCard.id, backCard.fileName)}> {backCard.fileName}</Link>
												</Text>
											}
											<FileUploader
												maxFileSize={maxFileSize}
												chunkSize={190000}
												multiple={false}
												uploadMode={'instantly'}
												accept={'*'}
												allowedFileExtensions={fileExtensions}
												uploadUrl={BASE_URL + "/FileUploader/uploadBackOfInsuranceCard"}
												onUploaded={onSelectBackCard}
												onProgress={onUploadProgress}
												onUploadStarted={onUploadStarted}
												uploadHeaders={
													{ 'Authorization': 'Bearer ' + _token.accessToken }
												}
											/>
											<FileUploaderValidation fileExtensions={fileExtensions} maxFileSize={maxFileSize} />
										</Grid>
									</Grid>
								</Fieldset>
							</Grid>
						</>
					}
					<Grid item xs={GRID_XS}>
						<Text disabled={decline}>
							I hereby authorize the representatives of APIA to verify information provided on this application. I
							authorize my insurance benefits to be paid directly to APIA and authorize to release information
							regarding my office visits to my insurance company or other third party to see settlement of my
							account. I certify that the statements regarding the persons and income in my household are true
							and correct. I understand I may be responsible for the full amount of my visit if the information is
							found to be inaccurate and I may be denied a discount for knowingly providing false information.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={decline}>
							The information provided herein will be kept confidential and I agree to notify APIA primary care
							or behavioral health of any changes in income, address and number of household members. I have
							been advised that I must provide proof of income within the 15 calendar day of approval and if not, then I will be required to pay 100% of the fees. I am
							aware that the discount fee discount is a payment of last resort. If I qualify for other resources; such
							as Medicaid and/or Denali Kid Care I must comply with the APIA discount fee policy. Medical
							services will not be denied based on ability to pay
						</Text>
					</Grid>
					{false &&
						<Grid item xs={GRID_XS}>
							<Fieldset disabled={decline}>
								<Grid container spacing={2}>
									<Grid item xs={GRID_XS}>
										<Text>
											<b>TO BE FILLED OUT BY OFFICE STAFF ONLY</b>
										</Text>
									</Grid>
									<Grid item xs={GRID_XS}>
										<Tooltip title="Employment Verified with">
											<>
												<TagBox
													label="Employment Verified with"
													valueExpr="Oid"
													displayExpr="Name"
													defaultValue={verification}
													dataSource={verifications}
													onValueChanged={onChangeVerification}
													disabled={decline}
													labelMode="floating"
													stylingMode="outlined"
													showDropDownButton={true}
													searchEnabled={true} />
											</>
										</Tooltip>
									</Grid>
									{
										verification?.includes(verifications?.find((e) => e?.Name === "Other")?.Oid) &&
										<Grid item xs={GRID_XS}>
											<Tooltip title="Other">
												<>
													<TextBox
														label="Other"
														value={otherVerification}
														onValueChanged={onChangeOtherVerification}
														disabled={decline}
														maxLength={30}
														labelMode="floating"
														stylingMode="outlined"
														inputAttr={{ autocomplete: 'new-otherVerification' }} />
												</>
											</Tooltip>
										</Grid>
									}
									<Grid item xs={GRID_XS}>
										<Tooltip title="Program applicant may qualify for">
											<>
												<TagBox
													label="Program applicant may qualify for"
													valueExpr="Oid"
													displayExpr="Name"
													defaultValue={applicantMayQualify}
													dataSource={applicantMayQualifies}
													onValueChanged={onChangeApplicantMayQualify}
													disabled={decline}
													labelMode="floating"
													stylingMode="outlined"
													showDropDownButton={true}
													searchEnabled={true} />
											</>
										</Tooltip>
									</Grid>
									<Grid item xs={GRID_XS} sm={GRID_SM}>
										<Tooltip title="Eligibility Date">
											<>
												<DateBox
													label="Eligibility Date"
													type="date"
													value={dateFormatFromApi(eligibilityDate)}
													onValueChanged={onChangeEligibilityDate}
													disabled={decline}
													useMaskBehavior={true}
													displayFormat="MM/dd/yyyy"
													invalidDateMessage="The date must have the following format: MM/dd/yyyy"
													labelMode="floating"
													stylingMode="outlined"
												>
													<Validator validationGroup={sectionName}>
														<RangeRule
															message="Date cannot be earlier than 1900 and later than 2100"
															min={MIN_DATE}
															max={MAX_DATE} />
													</Validator>
												</DateBox>
											</>
										</Tooltip>
									</Grid>
									<Grid item xs={GRID_XS} sm={GRID_SM}>
										<Tooltip title="Renewal Date">
											<>
												<DateBox
													label="Renewal Date"
													type="date"
													value={dateFormatFromApi(renewalDate)}
													onValueChanged={onChangeRenewalDate}
													disabled={decline}
													useMaskBehavior={true}
													displayFormat="MM/dd/yyyy"
													invalidDateMessage="The date must have the following format: MM/dd/yyyy"
													labelMode="floating"
													stylingMode="outlined"
												>
													<Validator validationGroup={sectionName}>
														<RangeRule
															message="Date cannot be earlier than 1900 and later than 2100"
															min={MIN_DATE}
															max={MAX_DATE} />
													</Validator>
												</DateBox>
											</>
										</Tooltip>
									</Grid>
									<Grid item xs={GRID_XS}>
										<Tooltip title="Applicant Discount">
											<>
												<SelectBox
													label="Applicant Discount"
													valueExpr="Oid"
													displayExpr="Name"
													defaultValue={discount}
													dataSource={discounts}
													onValueChanged={onChangeDiscount}
													disabled={decline}
													labelMode="floating"
													stylingMode="outlined"
													showDropDownButton={true}
													searchEnabled={true}
													inputAttr={{ autocomplete: 'new-discount' }}
												/>
											</>
										</Tooltip>
									</Grid>
									<Grid item xs={GRID_XS} sm={GRID_SM}>
										<Tooltip title="Verified By">
											<>
												<TextBox
													label="Verified By"
													value={verifiedBy}
													onValueChanged={onChangeVerifiedBy}
													disabled={decline}
													maxLength={30}
													labelMode="floating"
													stylingMode="outlined"
													inputAttr={{ autocomplete: 'new-verifiedBy' }} />
											</>
										</Tooltip>
									</Grid>
									<Grid item xs={GRID_XS} sm={GRID_SM}>
										<Tooltip title="Date">
											<>
												<DateBox
													label="Date"
													type="date"
													value={dateFormatFromApi(verifiedDate)}
													onValueChanged={onChangeVerifiedDate}
													disabled={decline}
													useMaskBehavior={true}
													displayFormat="MM/dd/yyyy"
													invalidDateMessage="The date must have the following format: MM/dd/yyyy"
													labelMode="floating"
													stylingMode="outlined"
												>
													<Validator validationGroup={sectionName}>
														<RangeRule
															message="Date cannot be earlier or later than that year"
															min={MIN_CURRENT_DATE}
															max={MAX_CURRENT_DATE} />
													</Validator>
												</DateBox>
											</>
										</Tooltip>
									</Grid>
								</Grid>
							</Fieldset>
						</Grid>
					}
					<Grid item xs={GRID_XS}>
						<Tooltip title="Date">
							<>
								<DateBox
									label="Date"
									type="date"
									value={dateFormatFromApi(date)}
									onValueChanged={onChangeDate}
									disabled={decline}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined">
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Signature
							error={error}
							disabled={decline}>
							<legend>Signature *</legend>
							<SignatureCanvas
								ref={onInitSignature}
								onEnd={onChangeSignature}
								clearOnResize={false}
								canvasProps={{
									id: 'responsive-canvas'
								}}
							/>
						</Signature>
					</Grid>
					<Grid item xs={GRID_XS} >
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								validationGroup={sectionName}
								disabled={decline}
								onClick={onSaveDiscountSchedule} />
							<Button
								text="Clear Signature"
								type="default"
								stylingMode="outlined"
								disabled={decline}
								onClick={onClearSignature} />
						</Buttons>
					</Grid>
				</Grid>
			}
		</Wrapper>
	)
}