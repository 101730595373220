import TextBox from 'devextreme-react/text-box';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';

export const TextBoxStyled = styled(TextBox)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

export const IconButtonStyled = styled(IconButton)(() => ({
    order: '2',
    marginRight: '10px',
}));
