import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';

import { setRoiValue, setProfileCratedInCernerValue, setInsuranceUploadedCernerValue, setInsuranceVerifiedValue, setInsuranceSubmittedValue, setIdVerifiedValue, setIdUploadedCernerValue, setDateChartCreatedCernerValue, setDateReferralApprovedValue, setDateReferralReceviedValue, setDobValue, setFrontDeskStaffValue, setIdSubmittedValue, setLoading, setNoppValue, setPatientNameValueFirstName, setPatientNameValueLastName, setPatientNameValueMiddleName, setReferralSourceValue, setReferralTypeValue, setRegistrationPacketUploadCernerValue, toggleCommunicationConsentCheck, toggleCommunicationConsentValueEmail, toggleCommunicationConsentValueTelehealth, toggleDateChartCreatedCernerCheck, toggleDateReferralApprovedCheck, toggleDateReferralReceviedCheck, toggleDobCheck, toggleFrontDeskStaffCheck, toggleIdSubmittedCheck, toggleIdUploadedCernerCheck, toggleIdVerifiedCheck, toggleInsuranceSubmittedCheck, toggleInsuranceUploadedCernerCheck, toggleInsuranceVerifiedCheck, toggleNoppCheck, togglePatientNameCheck, toggleProfileCratedInCernerCheck, toggleReferralSourceCheck, toggleReferralTypeCheck, toggleRegistrationPacketUploadCernerCheck, toggleRoiCheck, toggleRegFormCheck, setRegFormValue } from "../../../../store/users/invitedProfile/checklist/slice";
import { selectRegFormValue, selectCommunicationConsentCheck, selectCommunicationConsentValueEmail, selectCommunicationConsentValueTelehealth, selectDateChartCreatedCernerCheck, selectDateChartCreatedCernerValue, selectDateReferralApprovedCheck, selectDateReferralApprovedValue, selectDateReferralReceviedCheck, selectDateReferralReceviedValue, selectDobCheck, selectDobValue, selectFrontDeskStaffCheck, selectFrontDeskStaffValue, selectIdSubmittedCheck, selectIdSubmittedValue, selectIdUploadedCernerCheck, selectIdUploadedCernerValue, selectIdVerifiedCheck, selectIdVerifiedValue, selectInsuranceSubmittedCheck, selectInsuranceSubmittedValue, selectInsuranceUploadedCernerCheck, selectInsuranceUploadedCernerValue, selectInsuranceVerifiedCheck, selectInsuranceVerifiedValue, selectLoading, selectNoppCheck, selectNoppValue, selectPatientNameCheck, selectPatientNameValueFirstName, selectPatientNameValueLastName, selectPatientNameValueMiddleName, selectProfileCratedInCernerCheck, selectProfileCratedInCernerValue, selectReferralSourceCheck, selectReferralSourceValue, selectReferralTypeCheck, selectReferralTypeValue, selectRegFormCheck, selectRegistrationPacketUploadCernerCheck, selectRegistrationPacketUploadCernerValue, selectRoiCheck, selectRoiValue } from "../../../../store/users/invitedProfile/checklist/selectors";
import { getPatientChecklist, savePatientChecklist, changeInviteRole, getPatientChecklistById, savePatientChecklistById, checkChangeInviteRole } from "../../../../store/users/invitedProfile/checklist/middlewares";
import { PatientChecklist } from './PatientChecklist';

export const PatientChecklistContainer = ({ inviteID, open, setOpen }) => {
    const [timer, setTimer] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const frontDeskStaffCheck = useSelector(selectFrontDeskStaffCheck);
    const frontDeskStaffValue = useSelector(selectFrontDeskStaffValue);
    const patientNameCheck = useSelector(selectPatientNameCheck);
    const patientNameValueFirstName = useSelector(selectPatientNameValueFirstName);
    const patientNameValueMiddleName = useSelector(selectPatientNameValueMiddleName);
    const patientNameValueLastName = useSelector(selectPatientNameValueLastName);
    const dobCheck = useSelector(selectDobCheck);
    const dobValue = useSelector(selectDobValue);
    const dateReferralReceviedCheck = useSelector(selectDateReferralReceviedCheck);
    const dateReferralReceviedValue = useSelector(selectDateReferralReceviedValue);
    const dateReferralApprovedCheck = useSelector(selectDateReferralApprovedCheck);
    const dateReferralApprovedValue = useSelector(selectDateReferralApprovedValue);
    const referralSourceCheck = useSelector(selectReferralSourceCheck);
    const referralSourceValue = useSelector(selectReferralSourceValue);
    const referralTypeCheck = useSelector(selectReferralTypeCheck);
    const referralTypeValue = useSelector(selectReferralTypeValue);
    const regFormCheck = useSelector(selectRegFormCheck);
    const regFormValue = useSelector(selectRegFormValue);
    const noppCheck = useSelector(selectNoppCheck);
    const noppValue = useSelector(selectNoppValue);
    const communicationConsentCheck = useSelector(selectCommunicationConsentCheck);
    const communicationConsentValueTelehealth = useSelector(selectCommunicationConsentValueTelehealth);
    const communicationConsentValueEmail = useSelector(selectCommunicationConsentValueEmail);
    const dateChartCreatedCernerCheck = useSelector(selectDateChartCreatedCernerCheck);
    const dateChartCreatedCernerValue = useSelector(selectDateChartCreatedCernerValue);
    const registrationPacketUploadCernerCheck = useSelector(selectRegistrationPacketUploadCernerCheck);
    const registrationPacketUploadCernerValue = useSelector(selectRegistrationPacketUploadCernerValue);
    const idSubmittedCheck = useSelector(selectIdSubmittedCheck);
    const idSubmittedValue = useSelector(selectIdSubmittedValue);
    const idVerifiedCheck = useSelector(selectIdVerifiedCheck);
    const idVerifiedValue = useSelector(selectIdVerifiedValue);
    const idUploadedCernerCheck = useSelector(selectIdUploadedCernerCheck);
    const idUploadedCernerValue = useSelector(selectIdUploadedCernerValue);
    const insuranceSubmittedCheck = useSelector(selectInsuranceSubmittedCheck);
    const insuranceSubmittedValue = useSelector(selectInsuranceSubmittedValue);
    const insuranceVerifiedCheck = useSelector(selectInsuranceVerifiedCheck);
    const insuranceVerifiedValue = useSelector(selectInsuranceVerifiedValue);
    const insuranceUploadedCernerCheck = useSelector(selectInsuranceUploadedCernerCheck);
    const insuranceUploadedCernerValue = useSelector(selectInsuranceUploadedCernerValue);
    const profileCratedInCernerCheck = useSelector(selectProfileCratedInCernerCheck);
    const profileCratedInCernerValue = useSelector(selectProfileCratedInCernerValue);
    const roiCheck = useSelector(selectRoiCheck);
    const roiValue = useSelector(selectRoiValue);

    useEffect(() => {
        dispatch(setLoading(false));
        if (id !== undefined) {
            dispatch(getPatientChecklistById(id));
        } else {
            dispatch(getPatientChecklist());
        };

        return () => clearTimeout(timer);
    }, [dispatch, timer, id]);

    const onToggleFrontDeskStaffCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleFrontDeskStaffCheck());
        }
    };

    const onChangeFrontDeskStaffValue = (e) => {
        dispatch(setFrontDeskStaffValue(e.value.trim()));
    };

    const onTogglePatientNameCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(togglePatientNameCheck());
        };
    };

    const onChangePatientNameValueFirstName = (e) => {
        dispatch(setPatientNameValueFirstName(e.value.trim()));
    };

    const onChangePatientNameValueMiddleName = (e) => {
        dispatch(setPatientNameValueMiddleName(e.value.trim()));
    };

    const onChangePatientNameValueLastName = (e) => {
        dispatch(setPatientNameValueLastName(e.value.trim()));
    };

    const onToggleDobCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleDobCheck());
        };
    };

    const onChangeDobValue = (e) => {
        dispatch(setDobValue(e.value));
    };

    const onToggleDateReferralReceviedCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleDateReferralReceviedCheck());
        };
    };

    const onChangeDateReferralReceviedValue = (e) => {
        dispatch(setDateReferralReceviedValue(e.value));
    };

    const onToggleDateReferralApprovedCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleDateReferralApprovedCheck());
        };
    };

    const onChangeDateReferralApprovedValue = (e) => {
        dispatch(setDateReferralApprovedValue(e.value));
    };

    const onToggleReferralSourceCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleReferralSourceCheck());
        };
    };

    const onChangeReferralSourceValue = (e) => {
        dispatch(setReferralSourceValue(e.value.trim()));
    };

    const onToggleReferralTypeCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleReferralTypeCheck());
        };
    };

    const onChangeReferralTypeValue = (e) => {
        dispatch(setReferralTypeValue(e.value.trim()));
    };

    const onToggleRegFormCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleRegFormCheck());
        };
    };

    const onChangeRegFormValue = (e) => {
        dispatch(setRegFormValue(e.value.trim()));
    };

    const onToggleNoppCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleNoppCheck());
        };
    };

    const onChangeNoppValue = (e) => {
        dispatch(setNoppValue(e.value.trim()));
    };

    const onToggleCommunicationConsentCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleCommunicationConsentCheck());
        };
    };

    const onToggleCommunicationConsentValueTelehealth = () => {
        dispatch(toggleCommunicationConsentValueTelehealth());
    };

    const onToggleCommunicationConsentValueEmail = () => {
        dispatch(toggleCommunicationConsentValueEmail());
    };

    const onToggleDateChartCreatedCernerCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleDateChartCreatedCernerCheck());
        };
    };

    const onChangeDateChartCreatedCernerValue = (e) => {
        dispatch(setDateChartCreatedCernerValue(e.value));
    };

    const onToggleRegistrationPacketUploadCernerCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleRegistrationPacketUploadCernerCheck());
        };
    };

    const onChangeRegistrationPacketUploadCernerValue = (e) => {
        dispatch(setRegistrationPacketUploadCernerValue(e.value));
    };

    const onToggleIdSubmittedCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleIdSubmittedCheck());
        };
    };

    const onChangeIdSubmittedValue = (e) => {
        dispatch(setIdSubmittedValue(e.value));
    };

    const onToggleIdVerifiedCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleIdVerifiedCheck());
        };
    };

    const onChangeIdVerifiedValue = (e) => {
        dispatch(setIdVerifiedValue(e.value));
    };

    const onToggleIdUploadedCernerCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleIdUploadedCernerCheck());
        };
    };

    const onChangeIdUploadedCernerValue = (e) => {
        dispatch(setIdUploadedCernerValue(e.value));
    };

    const onToggleIdInsuranceSubmittedCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleInsuranceSubmittedCheck());
        };
    };

    const onChangeInsuranceSubmittedValue = (e) => {
        dispatch(setInsuranceSubmittedValue(e.value));
    };

    const onToggleInsuranceVerifiedCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleInsuranceVerifiedCheck());
        };
    };

    const onChangeInsuranceVerifiedValue = (e) => {
        dispatch(setInsuranceVerifiedValue(e.value));
    };

    const onToggleInsuranceUploadedCernerCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleInsuranceUploadedCernerCheck());
        };
    };

    const onChangeInsuranceUploadedCernerValue = (e) => {
        dispatch(setInsuranceUploadedCernerValue(e.value));
    };

    const onToggleProfileCratedInCernerCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleProfileCratedInCernerCheck());
        };
    };

    const onChangeProfileCratedInCernerValue = (e) => {
        dispatch(setProfileCratedInCernerValue(e.value));
    };

    const onToggleRoiCheck = (e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(toggleRoiCheck());
        };
    };

    const onChangeRoiValue = (e) => {
        dispatch(setRoiValue(e.value));
    };

    const onSavePatientChecklist = () => {
        if (id !== undefined) {
            dispatch(savePatientChecklistById(id));
        } else {
            dispatch(savePatientChecklist());
        };
    };

    const onChangeInviteRole = () => {
        dispatch(checkChangeInviteRole(inviteID)).then((data) => {
            if (data.payload.key) {
                dispatch(changeInviteRole(inviteID))
                    .unwrap()
                    .then(() => {
                        const timerID = setTimeout(() => navigate('/main/users/patients'), 2000);
                        setTimer(timerID);
                    })
                    .catch(() => { });
            };
        });
    };

    return (
        <PatientChecklist
            loading={loading}
            open={open}
            setOpen={setOpen}

            frontDeskStaffCheck={frontDeskStaffCheck}
            frontDeskStaffValue={frontDeskStaffValue}
            patientNameCheck={patientNameCheck}
            patientNameValueFirstName={patientNameValueFirstName}
            patientNameValueMiddleName={patientNameValueMiddleName}
            patientNameValueLastName={patientNameValueLastName}
            dobCheck={dobCheck}
            dobValue={dobValue}
            dateReferralReceviedCheck={dateReferralReceviedCheck}
            dateReferralReceviedValue={dateReferralReceviedValue}
            dateReferralApprovedCheck={dateReferralApprovedCheck}
            dateReferralApprovedValue={dateReferralApprovedValue}
            referralSourceCheck={referralSourceCheck}
            referralSourceValue={referralSourceValue}
            referralTypeCheck={referralTypeCheck}
            referralTypeValue={referralTypeValue}
            regFormCheck={regFormCheck}
            regFormValue={regFormValue}
            noppCheck={noppCheck}
            noppValue={noppValue}
            communicationConsentCheck={communicationConsentCheck}
            communicationConsentValueTelehealth={communicationConsentValueTelehealth}
            communicationConsentValueEmail={communicationConsentValueEmail}
            dateChartCreatedCernerCheck={dateChartCreatedCernerCheck}
            dateChartCreatedCernerValue={dateChartCreatedCernerValue}
            registrationPacketUploadCernerCheck={registrationPacketUploadCernerCheck}
            registrationPacketUploadCernerValue={registrationPacketUploadCernerValue}
            idSubmittedCheck={idSubmittedCheck}
            idSubmittedValue={idSubmittedValue}
            idVerifiedCheck={idVerifiedCheck}
            idVerifiedValue={idVerifiedValue}
            idUploadedCernerCheck={idUploadedCernerCheck}
            idUploadedCernerValue={idUploadedCernerValue}
            insuranceSubmittedCheck={insuranceSubmittedCheck}
            insuranceSubmittedValue={insuranceSubmittedValue}
            insuranceVerifiedCheck={insuranceVerifiedCheck}
            insuranceVerifiedValue={insuranceVerifiedValue}
            insuranceUploadedCernerCheck={insuranceUploadedCernerCheck}
            insuranceUploadedCernerValue={insuranceUploadedCernerValue}
            profileCratedInCernerCheck={profileCratedInCernerCheck}
            profileCratedInCernerValue={profileCratedInCernerValue}
            roiCheck={roiCheck}
            roiValue={roiValue}

            onToggleFrontDeskStaffCheck={onToggleFrontDeskStaffCheck}
            onChangeFrontDeskStaffValue={onChangeFrontDeskStaffValue}
            onTogglePatientNameCheck={onTogglePatientNameCheck}
            onChangePatientNameValueFirstName={onChangePatientNameValueFirstName}
            onChangePatientNameValueMiddleName={onChangePatientNameValueMiddleName}
            onChangePatientNameValueLastName={onChangePatientNameValueLastName}
            onToggleDobCheck={onToggleDobCheck}
            onChangeDobValue={onChangeDobValue}
            onToggleDateReferralReceviedCheck={onToggleDateReferralReceviedCheck}
            onChangeDateReferralReceviedValue={onChangeDateReferralReceviedValue}
            onToggleDateReferralApprovedCheck={onToggleDateReferralApprovedCheck}
            onChangeDateReferralApprovedValue={onChangeDateReferralApprovedValue}
            onToggleReferralSourceCheck={onToggleReferralSourceCheck}
            onChangeReferralSourceValue={onChangeReferralSourceValue}
            onToggleReferralTypeCheck={onToggleReferralTypeCheck}
            onChangeReferralTypeValue={onChangeReferralTypeValue}
            onToggleRegFormCheck={onToggleRegFormCheck}
            onChangeRegFormValue={onChangeRegFormValue}
            onToggleNoppCheck={onToggleNoppCheck}
            onChangeNoppValue={onChangeNoppValue}
            onToggleCommunicationConsentCheck={onToggleCommunicationConsentCheck}
            onToggleCommunicationConsentValueTelehealth={onToggleCommunicationConsentValueTelehealth}
            onToggleCommunicationConsentValueEmail={onToggleCommunicationConsentValueEmail}
            onToggleDateChartCreatedCernerCheck={onToggleDateChartCreatedCernerCheck}
            onChangeDateChartCreatedCernerValue={onChangeDateChartCreatedCernerValue}
            onToggleRegistrationPacketUploadCernerCheck={onToggleRegistrationPacketUploadCernerCheck}
            onChangeRegistrationPacketUploadCernerValue={onChangeRegistrationPacketUploadCernerValue}
            onToggleIdSubmittedCheck={onToggleIdSubmittedCheck}
            onChangeIdSubmittedValue={onChangeIdSubmittedValue}
            onToggleIdVerifiedCheck={onToggleIdVerifiedCheck}
            onChangeIdVerifiedValue={onChangeIdVerifiedValue}
            onToggleIdUploadedCernerCheck={onToggleIdUploadedCernerCheck}
            onChangeIdUploadedCernerValue={onChangeIdUploadedCernerValue}
            onToggleIdInsuranceSubmittedCheck={onToggleIdInsuranceSubmittedCheck}
            onChangeInsuranceSubmittedValue={onChangeInsuranceSubmittedValue}
            onToggleInsuranceVerifiedCheck={onToggleInsuranceVerifiedCheck}
            onChangeInsuranceVerifiedValue={onChangeInsuranceVerifiedValue}
            onToggleInsuranceUploadedCernerCheck={onToggleInsuranceUploadedCernerCheck}
            onChangeInsuranceUploadedCernerValue={onChangeInsuranceUploadedCernerValue}
            onToggleProfileCratedInCernerCheck={onToggleProfileCratedInCernerCheck}
            onChangeProfileCratedInCernerValue={onChangeProfileCratedInCernerValue}
            onToggleRoiCheck={onToggleRoiCheck}
            onChangeRoiValue={onChangeRoiValue}

            onSavePatientChecklist={onSavePatientChecklist}
            onChangeInviteRole={onChangeInviteRole}
        />
    );
}