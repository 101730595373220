import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../core/rest";

export const getPatientList = createAsyncThunk(
	"getPatientList",
	async (_, { rejectWithValue }) => {
		try {
			return await get(BASE_URL +  "/UserList/userlist?role=Patient");
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const deactivatePatient = createAsyncThunk(
  'deactivatePatient',
  async (data, { rejectWithValue }) => {
    try {
      return await post(BASE_URL + `/UserList/Deactivate?mail=${data}`);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initPatientList = createAsyncThunk(
  'initPatientList',
  async (data, { rejectWithValue }) => {
    try {
      return await post(BASE_URL + `/TestData/InitPatientList`);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);