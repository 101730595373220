import { useParams } from 'react-router-dom';
import { toggleDontHaveInsurance, setAddress, setAllMembers, toggleBenefitsVA, setCity, setCompanyInsurance, setDenaliKid, setDenaliKidExpDate, setDenaliKidNumber, setDOB, toggleDoNotOtherInsurance, setEffectiveDate, setPolicyHolderGroupNumber, setOtherInsuranceGroupNumber, setMedicaid, setMedicaidExpDate, setMedicaidNumber, setMedicare, setMedicareExpDate, setMedicareNumber, setOtherInsurance, setPhone, setPolicyHolder, setPolicyHolderNumber, setOtherInsuranceNumber, togglePrivateInsurance, setRelationship, setSelfPay, setServiceBranch, setServiceExitDate, setState, setZip, setTotalIncome, toggleVeteran, toggleVietnamService, setServiceEntryDate, setMedicareEffDate, setDenaliKidEffDate, setMedicaidEffDate } from "../../../../../store/forms/newPatientRegistration/insuranceInfo/slice";
import { selectDontHaveInsurance, selectAddress, selectTotalIncome, selectMedicare, selectMedicaid, selectDenaliKid, selectMedicaidExpDate, selectMedicareExpDate, selectMedicareNumber, selectAllMembers, selectBenefitsVA, selectCity, selectCompanyInsurance, selectDenaliKidExpDate, selectDenaliKidNumber, selectDOB, selectDoNotOtherInsurance, selectEffectiveDate, selectMedicaidNumber, selectPhone, selectPolicyHolderGroupNumber, selectPolicyHolder, selectPolicyHolderNumber, selectPrivateInsurance, selectRelationship, selectSelfPay, selectServiceBranch, selectServiceEntryDate, selectServiceExitDate, selectState, selectZip, selectVeteran, selectVietnamService, selectOtherInsurance, selectOtherInsuranceNumber, selectotherInsuranceGroupNumber, selectMedicareEffDate, selecMedicaidEffDate, selectDenaliKidEffDate, selectLoading } from "../../../../../store/forms/newPatientRegistration/insuranceInfo/selectors";
import { completeInsuranceInfo } from "../../../../../store/forms/newPatientRegistration/slice";

import { selectTotalHouseholdIncomes } from "../../../../../store/lookups/totalHouseholdIncome/selectors";
import { selectDenaliKidStatuses } from "../../../../../store/lookups/denaliKidStatus/selectors";
import { selectMedicaidStatuses } from "../../../../../store/lookups/medicaidStatus/selectors";
import { selectMedicareStatuses } from "../../../../../store/lookups/medicareStatus/selectors";
import { selectStates } from "../../../../../store/lookups/state/selectors";

import { getInsuranceInfo, getInsuranceInfoByID, saveInsuranceInfo, saveInsuranceInfoByID } from "../../../../../store/forms/newPatientRegistration/insuranceInfo/middlewares";
import { getTotalHouseholdIncomes } from "../../../../../store/lookups/totalHouseholdIncome/middlewares";
import { getDenaliKidStatuses } from "../../../../../store/lookups/denaliKidStatus/middlewares";
import { getMedicaidStatuses } from "../../../../../store/lookups/medicaidStatus/middlewares";
import { getMedicareStatuses } from "../../../../../store/lookups/medicareStatus/middlewares";
import { getState } from "../../../../../store/lookups/state/middlewares";

import { validateGroup } from "../../../../../utils/helper";
import { InsuranceInfo } from "./InsuranceInfo";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export const InsuranceInfoContainer = ({ onClickNext }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const totalHouseholdIncomes = useSelector(selectTotalHouseholdIncomes);
    const denaliKidStatuses = useSelector(selectDenaliKidStatuses);
    const medicaidStatuses = useSelector(selectMedicaidStatuses);
    const medicareStatuses = useSelector(selectMedicareStatuses);
    const states = useSelector(selectStates);

    const dontHaveInsurance = useSelector(selectDontHaveInsurance);
    const familyMembers = useSelector(selectAllMembers);
    const totalHouseholdIncome = useSelector(selectTotalIncome);
    const medicareStatus = useSelector(selectMedicare);
    const medicareEffDate = useSelector(selectMedicareEffDate);
    const medicareExpDate = useSelector(selectMedicareExpDate);
    const medicareNumber = useSelector(selectMedicareNumber);
    const medicaidStatus = useSelector(selectMedicaid);
    const medicaidEffDate = useSelector(selecMedicaidEffDate);
    const medicaidExpDate = useSelector(selectMedicaidExpDate);
    const medicaidNumber = useSelector(selectMedicaidNumber);
    const denaliKidStatus = useSelector(selectDenaliKid);
    const denaliKidEffDate = useSelector(selectDenaliKidEffDate);
    const denaliKidExpDate = useSelector(selectDenaliKidExpDate);
    const denaliKidNumber = useSelector(selectDenaliKidNumber);
    const privateInsurance = useSelector(selectPrivateInsurance);
    const companyInsurance = useSelector(selectCompanyInsurance);
    const policyHolder = useSelector(selectPolicyHolder);
    const policyHolderNumber = useSelector(selectPolicyHolderNumber);
    const policyHolderGroupNumber = useSelector(selectPolicyHolderGroupNumber);
    const otherInsurance = useSelector(selectOtherInsurance);
    const otherInsuranceNumber = useSelector(selectOtherInsuranceNumber);
    const otherInsuranceGroupNumber = useSelector(selectotherInsuranceGroupNumber);
    const veteran = useSelector(selectVeteran);
    const benefitsVA = useSelector(selectBenefitsVA);
    const serviceBranch = useSelector(selectServiceBranch);
    const serviceEntryDate = useSelector(selectServiceEntryDate);
    const serviceExitDate = useSelector(selectServiceExitDate);
    const vietnamService = useSelector(selectVietnamService);
    const doNotOtherInsurance = useSelector(selectDoNotOtherInsurance);
    const selfPay = useSelector(selectSelfPay);
    const relationship = useSelector(selectRelationship);
    const DOB = useSelector(selectDOB);
    const phone = useSelector(selectPhone);
    const address = useSelector(selectAddress);
    const city = useSelector(selectCity);
    const state = useSelector(selectState);
    const zip = useSelector(selectZip);
    const effectiveDate = useSelector(selectEffectiveDate);

    useEffect(() => {
        dispatch(id ? getInsuranceInfoByID(id) : getInsuranceInfo());
        dispatch(getTotalHouseholdIncomes());
        dispatch(getDenaliKidStatuses());
        dispatch(getMedicaidStatuses());
        dispatch(getMedicareStatuses());
        dispatch(getState());
    }, [dispatch, id]);

    const onToggleDontHaveInsurance = (event) => {
        dispatch(toggleDontHaveInsurance());

        dispatch(completeInsuranceInfo(false));
    }

    const onRowInsertingFamilyMembers = (event) => {
        const newValue = [...familyMembers, {
            id: new Date().toISOString(),
            name: event.data?.name,
            relationship: event.data?.relationship,
            age: event.data?.age
        }];

        event.cancel = true;
        event.component.cancelEditData();
        dispatch(setAllMembers(newValue));
    }

    const onRowRemovingFamilyMembers = (event) => {
        let newValue = [...familyMembers];
        const newId = newValue.findIndex(el => el.id === event.data?.id);
        newValue.splice(newId, 1);

        event.cancel = true;
        event.component.cancelEditData();
        dispatch(setAllMembers(newValue));
    }

    const onRowUpdatingFamilyMembers = (event) => {
        let newValue = [...familyMembers];
        const newId = familyMembers.findIndex(el => el.id === event.oldData?.id);
        newValue[newId] = { ...familyMembers[newId], ...event.newData };

        event.cancel = true;
        event.component.cancelEditData();
        dispatch(setAllMembers(newValue));
    }

    const onChangeIncome = (event) => {
        dispatch(setTotalIncome(event.value));
    };

    const onChangeMedicare = (event) => {
        dispatch(setMedicare(event.value));
    }

    const onChangeMedicareEffDate = (event) => {
        dispatch(setMedicareEffDate(event.value));
    }

    const onChangeMedicareExpDate = (event) => {
        dispatch(setMedicareExpDate(event.value));
    }

    const onChangeMedicareNumber = (event) => {
        dispatch(setMedicareNumber(event.value.trim()));
    }

    const onChangeMedicaid = (event) => {
        dispatch(setMedicaid(event.value));
    }

    const onChangeMedicaidEffDate = (event) => {
        dispatch(setMedicaidEffDate(event.value));
    }

    const onChangeMedicaidExpDate = (event) => {
        dispatch(setMedicaidExpDate(event.value));
    }

    const onChangeMedicaidNumber = (event) => {
        dispatch(setMedicaidNumber(event.value.trim()));
    }

    const onChangeDenaliKid = (event) => {
        dispatch(setDenaliKid(event.value));
    }

    const onChangeDenaliKidEffDate = (event) => {
        dispatch(setDenaliKidEffDate(event.value));
    }

    const onChangeDenaliKidExpDate = (event) => {
        dispatch(setDenaliKidExpDate(event.value));
    }

    const onChangeDenaliKidNumber = (event) => {
        dispatch(setDenaliKidNumber(event.value.trim()));
    }

    const onTogglePrivateInsurance = () => {
        dispatch(togglePrivateInsurance());
    }

    const onChangeCompanyInsurance = (event) => {
        dispatch(setCompanyInsurance(event.value.trim()));
    }

    const onChangePolicyHolder = (event) => {
        dispatch(setPolicyHolder(event.value.trim()));
    }

    const onChangePolicyHolderNumber = (event) => {
        dispatch(setPolicyHolderNumber(event.value.trim()));
    }

    const onChangePolicyHolderGroupNumber = (event) => {
        dispatch(setPolicyHolderGroupNumber(event.value.trim()));
    }

    const onChangeOtherInsurance = (event) => {
        dispatch(setOtherInsurance(event.value.trim()));
    }

    const onChangeOtherInsuranceNumber = (event) => {
        dispatch(setOtherInsuranceNumber(event.value.trim()));
    }

    const onChangeOtherInsuranceGroupNumber = (event) => {
        dispatch(setOtherInsuranceGroupNumber(event.value.trim()));
    }

    const onToggleVeteran = () => {
        dispatch(toggleVeteran());
    }

    const onToggleBenefitsVA = () => {
        dispatch(toggleBenefitsVA());
    }

    const onChangeServiceBranch = (event) => {
        dispatch(setServiceBranch(event.value.trim()));
    }

    const onChangeServiceEntryDate = (event) => {
        dispatch(setServiceEntryDate(event.value));
    }

    const onChangeServiceExitDate = (event) => {
        dispatch(setServiceExitDate(event.value));
    }

    const onToggleVietnamService = () => {
        dispatch(toggleVietnamService());
    }

    const onToggleDoNotOtherInsurance = () => {
        dispatch(toggleDoNotOtherInsurance());
    }

    const onChangeSelfPay = (event) => {
        dispatch(setSelfPay(event.value.trim()));
    }

    const onChangeRelationship = (event) => {
        dispatch(setRelationship(event.value.trim()));
    }

    const onChangeDOB = (event) => {
        dispatch(setDOB(event.value));
    }

    const onChangePhone = (event) => {
        dispatch(setPhone(event.value));
    }

    const onChangeAddress = (event) => {
        dispatch(setAddress(event.value.trim()));
    }

    const onChangeCity = (event) => {
        dispatch(setCity(event.value.trim()));
    }

    const onChangeState = (event) => {
        dispatch(setState(event.value));
    }

    const onChangeZip = (event) => {
        dispatch(setZip(event.value));
    }

    const onChangeEffectiveDate = (event) => {
        dispatch(setEffectiveDate(event.value));
    }

    const onSaveInsuranceInfo = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveInsuranceInfoByID(id) : saveInsuranceInfo());
            dispatch(completeInsuranceInfo(true));
        }
    }

    const onSaveAndContinueInsuranceInfo = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveInsuranceInfoByID(id) : saveInsuranceInfo());
            dispatch(completeInsuranceInfo(true));
            onClickNext();
        }
    }

    return (
        <InsuranceInfo
            loading={loading}

            totalHouseholdIncomes={totalHouseholdIncomes}
            denaliKidStatuses={denaliKidStatuses}
            medicaidStatuses={medicaidStatuses}
            medicareStatuses={medicareStatuses}
            states={states}

            dontHaveInsurance={dontHaveInsurance}
            familyMembers={familyMembers}
            totalHouseholdIncome={totalHouseholdIncome}
            medicareStatus={medicareStatus}
            medicareEffDate={medicareEffDate}
            medicareExpDate={medicareExpDate}
            medicareNumber={medicareNumber}
            medicaidStatus={medicaidStatus}
            medicaidEffDate={medicaidEffDate}
            medicaidExpDate={medicaidExpDate}
            medicaidNumber={medicaidNumber}
            denaliKidStatus={denaliKidStatus}
            denaliKidEffDate={denaliKidEffDate}
            denaliKidExpDate={denaliKidExpDate}
            denaliKidNumber={denaliKidNumber}
            privateInsurance={privateInsurance}
            companyInsurance={companyInsurance}
            policyHolder={policyHolder}
            policyHolderNumber={policyHolderNumber}
            policyHolderGroupNumber={policyHolderGroupNumber}
            otherInsurance={otherInsurance}
            otherInsuranceNumber={otherInsuranceNumber}
            otherInsuranceGroupNumber={otherInsuranceGroupNumber}
            veteran={veteran}
            benefitsVA={benefitsVA}
            serviceBranch={serviceBranch}
            serviceEntryDate={serviceEntryDate}
            serviceExitDate={serviceExitDate}
            vietnamService={vietnamService}
            doNotOtherInsurance={doNotOtherInsurance}
            selfPay={selfPay}
            relationship={relationship}
            DOB={DOB}
            phone={phone}
            address={address}
            city={city}
            state={state}
            zip={zip}
            effectiveDate={effectiveDate}

            onToggleDontHaveInsurance={onToggleDontHaveInsurance}
            onRowInsertingFamilyMembers={onRowInsertingFamilyMembers}
            onRowRemovingFamilyMembers={onRowRemovingFamilyMembers}
            onRowUpdatingFamilyMembers={onRowUpdatingFamilyMembers}
            onChangeIncome={onChangeIncome}
            onChangeMedicare={onChangeMedicare}
            onChangeMedicareEffDate={onChangeMedicareEffDate}
            onChangeMedicareExpDate={onChangeMedicareExpDate}
            onChangeMedicareNumber={onChangeMedicareNumber}
            onChangeMedicaid={onChangeMedicaid}
            onChangeMedicaidEffDate={onChangeMedicaidEffDate}
            onChangeMedicaidExpDate={onChangeMedicaidExpDate}
            onChangeMedicaidNumber={onChangeMedicaidNumber}
            onChangeDenaliKid={onChangeDenaliKid}
            onChangeDenaliKidEffDate={onChangeDenaliKidEffDate}
            onChangeDenaliKidExpDate={onChangeDenaliKidExpDate}
            onChangeDenaliKidNumber={onChangeDenaliKidNumber}
            onTogglePrivateInsurance={onTogglePrivateInsurance}
            onChangeCompanyInsurance={onChangeCompanyInsurance}
            onChangePolicyHolder={onChangePolicyHolder}
            onChangePolicyHolderNumber={onChangePolicyHolderNumber}
            onChangePolicyHolderGroupNumber={onChangePolicyHolderGroupNumber}
            onChangeOtherInsurance={onChangeOtherInsurance}
            onChangeOtherInsuranceNumber={onChangeOtherInsuranceNumber}
            onChangeOtherInsuranceGroupNumber={onChangeOtherInsuranceGroupNumber}
            onToggleVeteran={onToggleVeteran}
            onToggleBenefitsVA={onToggleBenefitsVA}
            onChangeServiceBranch={onChangeServiceBranch}
            onChangeServiceEntryDate={onChangeServiceEntryDate}
            onChangeServiceExitDate={onChangeServiceExitDate}
            onToggleVietnamService={onToggleVietnamService}
            onToggleDoNotOtherInsurance={onToggleDoNotOtherInsurance}
            onChangeSelfPay={onChangeSelfPay}
            onChangeRelationship={onChangeRelationship}
            onChangeDOB={onChangeDOB}
            onChangePhone={onChangePhone}
            onChangeAddress={onChangeAddress}
            onChangeCity={onChangeCity}
            onChangeState={onChangeState}
            onChangeZip={onChangeZip}
            onChangeEffectiveDate={onChangeEffectiveDate}

            onSaveInsuranceInfo={onSaveInsuranceInfo}
            onSaveAndContinueInsuranceInfo={onSaveAndContinueInsuranceInfo}
        />
    )
};