import { useState } from 'react';
import DataGrid, { Column, Toolbar, Item, Pager, Selection, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import { Wrapper, Title } from '../../../components/Main/Main.style';
import { IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import Tooltip from '@mui/material/Tooltip';
import LoadPanel from 'devextreme-react/load-panel';
import { MAX_WIDTH } from '../../../utils/constants';
import { Switcher } from '../Switcher/Switcher';
import Button from '../../../components/Button/Button';
import GenerationInvitesContainer from '../GenerationInvites/GenerationInvitesContainer';

export const PatientsList = ({
	loading,
	dataSource,
	showInvite,
	showProfile,
	onOpenDialog,
}) => {
	const [disabled, setDisabled] = useState(true);
	const [selected, setSelected] = useState([]);

	const onSelectionChanged = ({ selectedRowsData }) => {
		if (!selectedRowsData.length) setDisabled(true);
		else setDisabled(false);
		setSelected(selectedRowsData);
	};
	
	return (
		<>
			<Wrapper id="patientList" maxWidth={MAX_WIDTH}>
				<LoadPanel
					visible={loading}
					position={{ of: '#consentForRelease' }} />
				{
					!loading &&
					<>
						<Title style={{ marginBottom: '20px' }}>Patients</Title>
						<DataGrid
							dataSource={dataSource}
							height={window.innerHeight - 200}
							columnMinWidth={200}
							showBorders={false}
							showRowLines={true}
							showColumnLines={false}
							hoverStateEnabled={true}
							wordWrapEnabled={true}
							onSelectionChanged={onSelectionChanged}
						>
							<SearchPanel visible={true} placeholder="Search" />
							<Toolbar>
								<Item location="before">
									<Switcher />
								</Item>
								<Item name="searchPanel" />
								<Item location="after" >
									<Button
										label="Invite"
										variant="contained"
										startIcon="akar-icons:paper-airplane"
										onClick={() => showInvite()} />
								</Item>
								<Item location="after" >
									<Button
										disabled={disabled}
										label="Delete"
										variant="contained"
										startIcon="ant-design:minus-square-outlined"
										onClick={() => onOpenDialog(selected)} />
								</Item>
							</Toolbar>
							<Selection mode="multiple" />
							<Column dataField="firstName" caption="First Name" minWidth={140} />
							<Column dataField="middleName" caption="Middle Name" minWidth={140} />
							<Column dataField="lastName" caption="Last Name" minWidth={140} />
							<Column dataField="email" caption="Email" minWidth={260} />
							<Column dataField="dob" caption="Date Of Birth" dataType="date" minWidth={130} />
							<Column dataField="address" caption="Address" minWidth={280} />
							<Column dataField="city" caption="City" minWidth={120} />
							<Column dataField="state" caption="State" minWidth={80} />
							<Column dataField="zip" caption="Zip" minWidth={100} />
							<Column dataField="phone" caption="Phone" minWidth={130} />
							<Column
								fixed={true}
								fixedPosition="right"
								alignment="center"
								width={80}
								minWidth={80}
								cellRender={
									(event) => {
										return (
											<IconButton
												onClick={() => showProfile(event.data.id)}
											>
												<Tooltip title="Go to profile">
												<Icon
													icon="ic:baseline-output"
													height="24px"
													width="24px"
													color="var(--light-blue-color)" />
												</Tooltip>
											</IconButton>
										)
									}
								} />
							<Pager
								visible={true}
								displayMode="full"
								label="Rows per page:"
								showPageSizeSelector={true}
								showNavigationButtons={true}
								allowedPageSizes={[5, 10, 'all']} />
							<Scrolling columnRenderingMode="virtual" />
						</DataGrid>
					</>
				}
			</Wrapper >
			<GenerationInvitesContainer />
		</>
	);
}