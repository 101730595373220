import { createSlice } from "@reduxjs/toolkit";
import { FETCH_STATUSES } from "../../../../../utils/constants";
import { postAdminChangePassword } from "./middlewares";

const initialState = {
    status: FETCH_STATUSES.IDLE,
    error: null,
    showButton: true,
};

export const admin = createSlice({
    name: "admin",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(postAdminChangePassword.pending, (state) => {
                state.status = FETCH_STATUSES.REQUEST;
                state.error = null;
                state.showButton = false;
            })
            .addCase(postAdminChangePassword.fulfilled, (state) => {
                state.status = FETCH_STATUSES.SUCCESS;
                state.error = null;
                state.showButton = true;
            })
            .addCase(postAdminChangePassword.rejected, (state, action) => {
                state.status = FETCH_STATUSES.FAILURE;
                state.error = Object.values(action.payload.errors) ?? ['Server error'];
                state.showButton = true;
            })
    },
});

export default admin.reducer;