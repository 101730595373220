import { Wrapper, TitleWrapper, Title, TabPanelWrapper, TabPanelStyled } from './InvitedProfile.style';
import { Item } from 'devextreme-react/tab-panel';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import { PatientChecklistContainer } from './PatientChecklist/PatientChecklistContainer';
import { BasicInformationContainer } from './BasicInformation/BasicInformationContainer';
import { AttachmentsContainer } from './Attachments/AttachmentsContainer';
import { FormsContainer } from './Forms/FormsContainer';


export const InvitedProfile = ({ onClickBack, dataSource }) => {
	const [open, setOpen] = useState(true);
	const [index, setIndex] = useState(0);
	const location = useLocation();

	const onSelectionChanged = (args) => {
		if (args.name === 'selectedIndex') {
			setIndex(args.value);
		}
	};

	useEffect(() => {
		setIndex(location.state?.indexTab || 0);
	}, [location.state?.indexTab]);

	return (
		<Wrapper>
			<TitleWrapper >
				<Tooltip title="Go back to Invited list">
					<span>
						<Button icon="back" onClick={onClickBack} />
					</span>
				</Tooltip>
				<Title>{`Invitee: ${dataSource?.firstName} ${dataSource?.middleName} ${dataSource?.lastName}`}</Title>
			</TitleWrapper>
			<TabPanelWrapper>
				<TabPanelStyled
					open={open}
					scrollByContent={true}
					showNavButtons={true}
					selectedIndex={index}
					onOptionChanged={onSelectionChanged}
				>
					<Item title="Basic information">
						<BasicInformationContainer />
					</Item>
					<Item title="Attachments ">
						<AttachmentsContainer />
					</Item>
					<Item title="Forms">
						<FormsContainer />
					</Item>
				</TabPanelStyled>
				<PatientChecklistContainer inviteID={dataSource?.id} open={open} setOpen={setOpen} />
			</TabPanelWrapper>
		</Wrapper >
	);
}