import { createSlice } from '@reduxjs/toolkit';
import { FETCH_STATUSES } from '../../../utils/constants';
import { showToast } from "../../../screens/Main/MainContainer";
import { getPatientList, deactivatePatient } from './middlewares';

const initialState = {
	value: [],
	getStatus: FETCH_STATUSES.IDLE,
	getError: null,
	loading: true,
	loadingDeactivate: false,
	activeValue: [],
	activePatientId: ""
};

const patientList = createSlice({
	name: 'patientList',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
			return state;
		},
		setActivePatientId: (state, action) => {
			state.activePatientId = action.payload;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPatientList.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(getPatientList.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;

				const compare = (a, b) => new Date(b?.dateAdded) - new Date(a?.dateAdded);
				const inviteList = action.payload.sort(compare);
				
				state.value = inviteList;
				state.value = action.payload.reverse();

				state.activeValue = action.payload.filter((patient) => patient.id === state.activePatientId)[0];
				state.loading = false;
				state.loadingDeactivate = false;
			})
			.addCase(getPatientList.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;
				showToast('error', `Get Patient List: ${action.error.message}`);
			})
			.addCase(deactivatePatient.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError  = null;
				state.loadingDeactivate = true;
			})
			.addCase(deactivatePatient.fulfilled, (state) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				showToast('success', 'Patient deleted successfully');
			})
			.addCase(deactivatePatient.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loadingDeactivate = false;
				showToast('error', 'Unable to delete patient');
			})
	},
});

export const {
	setLoading,
	setActiveValue,
	setActivePatientId
} = patientList.actions;

export default patientList.reducer;