import { DRAWER_WIDTH } from '../../utils/constants';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiBox from '@mui/material/Box';
import logo from '../../images/logo.png';

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    boxShadow: "none",
    backgroundColor: "var(--background-color)",
    borderBottom: "1px solid var(--light-grey-color)",
    zIndex: "9999999999999999999999999",
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '@media (max-width: 660px)': {
            display: 'none',
        },
    }),
}));

export const Wrapper = styled(MuiBox)(() => ({
    display: 'flex',
    height: '100vh',
}));

export const Logo = styled('div')(() => ({
    background: `url(${logo}) no-repeat`,
    backgroundSize: 'cover',
    userSelect: 'none',
    height: '55px',
    width: '180px',
    '@media (max-width: 768px)': {
        display: 'none',
    },
}));

export const TitleBox = styled(MuiBox)(() => ({
    height: '40px',
    display: 'flex',
    flexDirection: 'column',
}));

export const Title = styled('div')(() => ({
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    userSelect: 'none',
    color: 'var(--dark-blue-color)',
    '@media (max-width: 425px)': {
        fontSize: '14px',
    },
}));

export const SubTitle = styled('div')(() => ({
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    userSelect: 'none',
    textDecoration: 'none',
    color: 'var(--grey-text-color)',
    '@media (max-width: 425px)': {
        fontSize: '10px',
    },
}));

export const AppBox = styled(MuiBox, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ open }) => ({
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    flexGrow: 1,
    ...(open && {
        '@media (max-width: 660px)': {
            display: 'none',
        },
    }),
}));

