import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import SelectBox from 'devextreme-react/select-box';
import { SELECT_INVITED, SELECT_PATIENTS, SELECT_ADMINS, USER_PATH } from '../../../utils/constants';

const values = [SELECT_INVITED, SELECT_PATIENTS, SELECT_ADMINS];

export const Switcher = () => {
	let navigate = useNavigate();
	const location = useLocation();

	const [value] = useState(location.state?.value ? location.state.value : values[1]);

	if (location.state?.value === 'undefined') {
		navigate("/main/users/patients", { state: { value: values[1] } });
	}

	const onChange = (event) => {
		switch (event.value) {
			case values[0]:
				navigate("/main/users/invited", { state: { value: values[0] } });
				return;
			case values[1]:
				navigate("/main/users/patients", { state: { value: values[1] } });
				return;
			case values[2]:
				navigate("/main/users/admins", { state: { value: values[2] } });
				return;
			default:
				navigate("/main/users/patients", { state: { value: values[1] } });
		}
	};

	useEffect(() => {
		window.localStorage.setItem(USER_PATH, value.toLocaleLowerCase());
	}, [value]);

	return (
		<SelectBox
			value={value}
			dataSource={values}
			onValueChanged={onChange}
			labelMode="floating"
			stylingMode="outlined"
			style={{ height: '40px' }}
			searchEnabled={true}
			inputAttr={{ autocomplete: 'new-value' }}
		/>
	);
}