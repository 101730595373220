import { CssBaseline, Box, IconButton, Toolbar, ThemeProvider } from '@mui/material';
import { AppBar, TitleBox, AppBox, Logo, Wrapper, Title, SubTitle, DrawerHeader } from './Main.style';
import { Navigation } from './Navigation/Navigation';
import { Outlet } from 'react-router-dom';
import { Icon } from '@iconify/react';

export const Main = ({
	theme,
	open,
	onChangeOpen,
	userName,
	authRole
}) => {
	return (
		<ThemeProvider theme={theme}>
			<Wrapper>
				<CssBaseline />
				<AppBar position="fixed" open={open}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={() => onChangeOpen(true)} sx={{ ...(open && { display: 'none' }) }}>
							<Icon color="var(--grey-text-color)" icon="quill:hamburger" />
						</IconButton>
						<Logo style={{ ...(open ? { display: 'none' } : { margin: '0 70px 0 35px' }) }} />
						<TitleBox>
							<Title>Welcome back, {userName}!</Title>
							<SubTitle to="#">Aleutian Pribilof Islands Association Web Portal</SubTitle>
						</TitleBox>
						<Box sx={{ flexGrow: 1 }} />
					</Toolbar>
				</AppBar>
				<Navigation authRole={authRole} open={open} onClick={() => onChangeOpen(false)} />
				<AppBox component="main" open={open}>
					<DrawerHeader />
					<Outlet />
				</AppBox>
			</Wrapper >
		</ThemeProvider>
	);
}
