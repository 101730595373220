import iconPNG from '../../../images/apia.png';
import iconPNG1 from '../../../images/911.png';
import iconPNG2 from '../../../images/988.png';
import iconPNG3 from '../../../images/careline.png';
import iconPNG4 from '../../../images/national-domestic.png';
import iconPNG5 from '../../../images/aa.png';
import iconPNG6 from '../../../images/unalaska.png';

export const hotlineNumbers = [
  {
    title: '911',
    text: 'Emergency (call or text)',
    image: iconPNG1,
  },
  {
    title: '988',
    text: 'Suicide & Crisis Lifeline (call or text)',
    image: iconPNG2,
  },
  {
    title: '1-877-266-4357',
    text: 'Careline (Alaska’s Suicide & Someone-to-talk-to line)',
    image: iconPNG3,
  },
  {
    title: '1-800-799-7233',
    text: 'National Domestic Violence Hotline',
    image: iconPNG4,
  },
  {
    title: '1-907-272-2312',
    text: 'Statewide Alcoholics Anonymous',
    image: iconPNG5,
  },
  {
    title: '1-907-581-1233',
    text: 'Unalaska Department of Public Safety',
    image: iconPNG6,
  },
];

export const regionalClinicsContacts = [
  {
    title: '1-907-839-2232 or 1-907-581-2751',
    text: 'Atka Clinic, Atka',
    image: iconPNG,
  },
  {
    title: '1-907-576-2204 or 1-907-581-2751',
    text: 'Nikolski Clinic, Nikolski',
    image: iconPNG,
  },
  {
    title: '1-907-581-2751',
    text: 'Oonalaska Wellness Center, Unalaska',
    image: iconPNG,
  },
  {
    title: '1-907-859-2254 or 1-907-222-4218',
    text: 'Saint George Clinic, Saint George',
    image: iconPNG,
  },
];

export const APIAContacts = [
  {
    title: '1-907-222-9764 or 1-844-375-2742',
    text: 'APIA Behavioral Health Registration and Scheduling',
    image: iconPNG,
    email: 'registration@apiai.org',
  },
  {
    title: '1-844-375-2743',
    text: 'APIA Afterhours and Crisis Line',
    image: iconPNG,
  },
  {
    title: '1-907-276-2700',
    text: 'APIA Main Office, Anchorage',
    image: iconPNG,
  },
];
