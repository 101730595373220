import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentRow, DialogActions } from '../../../components/Dialog/Dialog.style';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import { Validator, RequiredRule, EmailRule } from 'devextreme-react/validator';
import { TextBoxStyled, IconButtonStyled } from './GenerationAdmins.style';
import { Icon } from '@iconify/react';

const GenerationAdmins = ({
	open,

	email,
	userName,
	password,

	onChangeEmail,
	onChangeUserName,
	onChangePassword,

	onClickClose,
	onClickCreateUser,
}) => {
	const sectionName = "generationAdmins";

	const [passMode, setPassMode] = useState("password");

	const changePassMode = () => {
		setPassMode(passMode === "text" ? "password" : "text");
	};

	return (
		<Dialog open={open} onClose={onClickClose} fullWidth maxWidth="sm">
			<DialogTitle>Add user</DialogTitle>
			<DialogContent>
				<DialogContentRow style={{ width: '100%' }} title="Email *">
					<TextBox
						label="Email *"
						value={email}
						onValueChanged={onChangeEmail}
						labelMode="floating"
						stylingMode="outlined"
						inputAttr={{ autocomplete: 'new-email' }}
					>
						<Validator validationGroup={sectionName}>
							<RequiredRule
								message="The Email field is required"
							/>
							<EmailRule
								message="Please enter a valid email address"
							/>
						</Validator>
					</TextBox>
				</DialogContentRow>
				<DialogContentRow style={{ width: '100%' }} title="User Name *">
					<TextBox
						label="User Name *"
						value={userName}
						onValueChanged={onChangeUserName}
						labelMode="floating"
						stylingMode="outlined"
						inputAttr={{ autocomplete: 'new-username' }}
					>
						<Validator validationGroup={sectionName}>
							<RequiredRule
								message="The User Name field is required"
							/>
						</Validator>
					</TextBox>
				</DialogContentRow>
				<DialogContentRow style={{ width: '100%' }} title="Password *">
					<TextBoxStyled
						label="Password *"
						value={password}
						onValueChanged={onChangePassword}
						labelMode="floating"
						stylingMode="outlined"
						mode={passMode}
						inputAttr={{ autocomplete: 'new-password' }}
					>
						<IconButtonStyled onClick={changePassMode} tabIndex={-1}>
							<Icon
								icon={passMode === "text" ? "bx:hide" : "bx:show"}
								height="30px"
								width="30px" />
						</IconButtonStyled>
						<Validator validationGroup={sectionName}>
							<RequiredRule
								message="The Password field is required"
							/>
						</Validator>
					</TextBoxStyled>
				</DialogContentRow>
			</DialogContent>
			<DialogActions>
				<Button
					text="Create"
					type="default"
					stylingMode="contained"
					validationGroup={sectionName}
					onClick={onClickCreateUser}
				/>
				<Button
					text="Cancel"
					type="default"
					stylingMode="outlined"
					onClick={onClickClose} />
			</DialogActions>
		</Dialog >
	);
};

export default GenerationAdmins;