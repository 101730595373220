import { Wrapper, Grid, Item, Label, TextBox, DateBox, SelectBox, Buttons } from '../../BasicInformation.style';
import { MAX_WIDTH, GUARDIAN_INFO, ZIP_MASK, PHONE_MASK, GRID_XS, GRID_SM, GRID_LG } from '../../../../../../utils/constants';
import { onFocusInTextboxMask, onFocusOutTextboxMask, onKeyDownHandle } from '../../../../../../utils/helper';
import { NAME_RULE } from '../../../../../../utils/rules';
import { dateFormatFromApi } from '../../../../../../utils/formats';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	EmailRule,
	PatternRule,
} from 'devextreme-react/validator';

export const GuardianInformation = ({
	showButton,

	states,

	getEntityPatientProfileGuardianInfo,
	onChangeFunc,

	onCancelGuardianInfo,
	onSaveGuardianInfo,
}) => {
	return (
		<Wrapper id={GUARDIAN_INFO} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileGuardianInfo('loading')}
				position={{ of: `#${GUARDIAN_INFO}` }} />
			{
				!getEntityPatientProfileGuardianInfo('loading') &&
				<Grid container spacing={2}>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="First Name">
							<Label>First Name:</Label>
						</Tooltip>
						<TextBox
							label="First Name *"
							value={getEntityPatientProfileGuardianInfo('firstName')}
							onValueChanged={e => onChangeFunc('FirstName', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-firstName' }}
						>
							<Validator validationGroup={GUARDIAN_INFO}>
								<PatternRule
									message="Do not use digits in the First Name"
									pattern={NAME_RULE}
								/>
							</Validator>
						</TextBox>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Middle Name">
							<Label>Middle Name:</Label>
						</Tooltip>
						<TextBox
							label="Middle Name"
							value={getEntityPatientProfileGuardianInfo('middleName')}
							onValueChanged={e => onChangeFunc('MiddleName', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-middleName' }}
						>
						</TextBox>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Last Name *">
							<Label>Last Name:</Label>
						</Tooltip>
						<TextBox
							label="Last Name"
							value={getEntityPatientProfileGuardianInfo('lastName')}
							onValueChanged={e => onChangeFunc('LastName', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-lastName' }}
						>
							<Validator validationGroup={GUARDIAN_INFO}>
								<PatternRule
									message="Do not use digits in the Last Name"
									pattern={NAME_RULE}
								/>
							</Validator>
						</TextBox>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Date of Birth">
							<Label>Date of Birth:</Label>
						</Tooltip>
						<DateBox
							type="date"
							label="Date of Birth"
							value={dateFormatFromApi(getEntityPatientProfileGuardianInfo('dateOfBirth'))}
							onValueChanged={e => onChangeFunc('DateOfBirth', e)}
							useMaskBehavior={true}
							showDropDownButton={false}
							displayFormat="MM/dd/yyyy"
							invalidDateMessage="The date must have the following format: MM/dd/yyyy"
							labelMode="hidden"
							stylingMode="underlined"
						>
						</DateBox>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Address">
							<Label>Address:</Label>
						</Tooltip>
						<TextBox
							label="Address"
							value={getEntityPatientProfileGuardianInfo('address')}
							onValueChanged={e => onChangeFunc('Address', e, true)}
							maxLength={150}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-address' }}
						/>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="State">
							<Label>State:</Label>
						</Tooltip>
						<SelectBox
							label="State"
							defaultValue={getEntityPatientProfileGuardianInfo('state')}
							valueExpr="Oid"
							displayExpr="Name"
							dataSource={states}
							onValueChanged={e => onChangeFunc('State', e)}
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-state' }}
						/>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="City">
							<Label>City:</Label>
						</Tooltip>
						<TextBox
							label="City"
							value={getEntityPatientProfileGuardianInfo('city')}
							onValueChanged={e => onChangeFunc('City', e, true)}
							maxLength={20}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-city' }}
						/>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Zip">
							<Label>Zip:</Label>
						</Tooltip>
						<TextBox
							label="Zip"
							value={getEntityPatientProfileGuardianInfo('zip')}
							onValueChanged={e => onChangeFunc('Zip', e)}
							labelMode="hidden"
							stylingMode="underlined"
							onKeyDown={onKeyDownHandle}
							onFocusIn={onFocusInTextboxMask}
							onFocusOut={onFocusOutTextboxMask}
							maskChar="."
							mask={ZIP_MASK}
							showMaskMode="onFocus"
							maskRules={{ X: /[0-9]/ }}
							maskInvalidMessage="Zip must have at least 5 symbols"
							validationMessagePosition="bottom"
							inputAttr={{ autocomplete: 'new-zip' }}
						/>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Phone">
							<Label>Phone:</Label>
						</Tooltip>
						<TextBox
							label="Phone"
							value={getEntityPatientProfileGuardianInfo('phone')}
							onValueChanged={e => onChangeFunc('Phone', e)}
							labelMode="hidden"
							stylingMode="underlined"
							onKeyDown={onKeyDownHandle}
							onFocusIn={onFocusInTextboxMask}
							onFocusOut={onFocusOutTextboxMask}
							maskChar="."
							mask={PHONE_MASK}
							showMaskMode="onFocus"
							maskRules={{ X: /[0-9]/ }}
							maskInvalidMessage="The phone must have a correct USA phone format"
							validationMessagePosition="bottom"
							inputAttr={{ autocomplete: 'new-phone' }}
						/>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Email *">
							<Label>Email:</Label>
						</Tooltip>
						<TextBox
							label="Email *"
							value={getEntityPatientProfileGuardianInfo('email')}
							onValueChanged={e => onChangeFunc('Email', e, true)}
							maxLength={64}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-email' }}
						>
							<Validator validationGroup={GUARDIAN_INFO}>
								<EmailRule
									message="Email is invalid"
								/>
							</Validator>
						</TextBox>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Employer">
							<Label>Employer:</Label>
						</Tooltip>
						<TextBox
							label="Employer"
							value={getEntityPatientProfileGuardianInfo('employer')}
							onValueChanged={e => onChangeFunc('Employer', e, true)}
							maxLength={120}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-employer' }}
						>
							<Validator validationGroup={GUARDIAN_INFO}>
								<PatternRule
									message="Do not use digits in the Name"
									pattern={NAME_RULE}
								/>
							</Validator>
						</TextBox>
					</Item>
					<Item item sm={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={GUARDIAN_INFO}
								onClick={onSaveGuardianInfo}
							/>
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelGuardianInfo}
							/>
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper>
	);
};