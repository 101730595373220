export const NAME_RULE = /^[a-z][a-z0-9-\s]*[a-z0-9]$/i;

export const GENDER_RULE = /^[a-z][a-z\s]*[a-z]$/i;

export const ADDRESS_RULE = /^[^\s].*[^\s]$/i;

export const CITY_RULE = /^[^0-9]+$/;

export const PHONE_RULE = /(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:(\\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*(?:[.-]\\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\\s*(?:[.-]\\s*)?([0-9]{2})\\s*(?:[.-]\\s*)?([0-9]{2})\\s*(?:\\s*(?:#|x\\.?|ext\\.?|extension)\\s*(\\d+)\\s*)?$/;

export const PASS_PATTERN_RULE = /(?=.*[0-9])(?=.*[@#!_$%&])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@#!_$%&]{8,}/;