import { getPatientChecklist, getPatientChecklistById, setPatientChecklist, setPatientChecklistById } from './middlewares';
import { showToast } from '../../../../screens/Main/MainContainer';
import { FETCH_STATUSES } from '../../../../utils/constants';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	frontDeskStaffCheck: false,
	frontDeskStaffValue: "",
	patientNameCheck: false,
	patientNameValueFirstName: "",
	patientNameValueMiddleName: "",
	patientNameValueLastName: "",
	dobCheck: false,
	dobValue: null,
	dateReferralReceviedCheck: false,
	dateReferralReceviedValue: null,
	dateReferralApprovedCheck: false,
	dateReferralApprovedValue: null,
	referralSourceCheck: false,
	referralSourceValue: "",
	referralTypeCheck: false,
	referralTypeValue: "",
	regFormCheck: false,
	regFormValue: "",
	noppCheck: false,
	noppValue: "",
	communicationConsentCheck: false,
	communicationConsentValueTelehealth: false,
	communicationConsentValueEmail: false,
	registrationPacketUploadCernerCheck: false,
	registrationPacketUploadCernerValue: false,
	dateChartCreatedCernerCheck: false,
	dateChartCreatedCernerValue: null,
	idSubmittedCheck: false,
	idSubmittedValue: false,
	idVerifiedCheck: false,
	idVerifiedValue: false,
	idUploadedCernerCheck: false,
	idUploadedCernerValue: false,
	insuranceSubmittedCheck: false,
	insuranceSubmittedValue: false,
	insuranceVerifiedCheck: false,
	insuranceVerifiedValue: false,
	insuranceUploadedCernerCheck: false,
	insuranceUploadedCernerValue: false,
	profileCratedInCernerCheck: false,
	profileCratedInCernerValue: false,
	roiCheck: false,
	roiValue: false,
	getStatus: FETCH_STATUSES.IDLE,
	getError: null,
	setStatus: FETCH_STATUSES.IDLE,
	setError: null,
	loading: false,
};

const patientChecklist = createSlice({
	name: 'patientChecklist',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
			return state;
		},
		toggleFrontDeskStaffCheck: (state) => {
			state.frontDeskStaffCheck = !state.frontDeskStaffCheck;
		},
		setFrontDeskStaffValue: (state, action) => {
			state.frontDeskStaffValue = action.payload;
		},
		togglePatientNameCheck: (state) => {
			state.patientNameCheck = !state.patientNameCheck;
		},
		setPatientNameValueFirstName: (state, action) => {
			state.patientNameValueFirstName = action.payload;
		},
		setPatientNameValueMiddleName: (state, action) => {
			state.patientNameValueMiddleName = action.payload;
		},
		setPatientNameValueLastName: (state, action) => {
			state.patientNameValueLastName = action.payload;
		},
		toggleDobCheck: (state) => {
			state.dobCheck = !state.dobCheck;
		},
		setDobValue: (state, action) => {
			state.dobValue = action.payload;
		},
		toggleDateReferralReceviedCheck: (state) => {
			state.dateReferralReceviedCheck = !state.dateReferralReceviedCheck;
		},
		setDateReferralReceviedValue: (state, action) => {
			state.dateReferralReceviedValue = action.payload;
		},
		toggleDateReferralApprovedCheck: (state) => {
			state.dateReferralApprovedCheck = !state.dateReferralApprovedCheck;
		},
		setDateReferralApprovedValue: (state, action) => {
			state.dateReferralApprovedValue = action.payload;
		},
		toggleReferralSourceCheck: (state) => {
			state.referralSourceCheck = !state.referralSourceCheck;
		},
		setReferralSourceValue: (state, action) => {
			state.referralSourceValue = action.payload;
		},
		toggleReferralTypeCheck: (state) => {
			state.referralTypeCheck = !state.referralTypeCheck;
		},
		setReferralTypeValue: (state, action) => {
			state.referralTypeValue = action.payload;
		},
		toggleRegFormCheck: (state) => {
			state.regFormCheck = !state.regFormCheck;
		},
		setRegFormValue: (state, action) => {
			state.regFormValue = action.payload;
		},
		toggleNoppCheck: (state) => {
			state.noppCheck = !state.noppCheck;
		},
		setNoppValue: (state, action) => {
			state.noppValue = action.payload;
		},
		toggleCommunicationConsentCheck: (state) => {
			state.communicationConsentCheck = !state.communicationConsentCheck;
		},
		toggleCommunicationConsentValueTelehealth: (state) => {
			state.communicationConsentValueTelehealth = !state.communicationConsentValueTelehealth;
		},
		toggleCommunicationConsentValueEmail: (state) => {
			state.communicationConsentValueEmail = !state.communicationConsentValueEmail;
		},
		toggleDateChartCreatedCernerCheck: (state) => {
			state.dateChartCreatedCernerCheck = !state.dateChartCreatedCernerCheck;
		},
		setDateChartCreatedCernerValue: (state, action) => {
			state.dateChartCreatedCernerValue = action.payload;
		},
		toggleRegistrationPacketUploadCernerCheck: (state) => {
			state.registrationPacketUploadCernerCheck = !state.registrationPacketUploadCernerCheck;
		},
		setRegistrationPacketUploadCernerValue: (state, action) => {
			state.registrationPacketUploadCernerValue = action.payload;
		},
		toggleIdSubmittedCheck: (state) => {
			state.idSubmittedCheck = !state.idSubmittedCheck;
		},
		setIdSubmittedValue: (state, action) => {
			state.idSubmittedValue = action.payload;
		},
		toggleIdVerifiedCheck: (state) => {
			state.idVerifiedCheck = !state.idVerifiedCheck;
		},
		setIdVerifiedValue: (state, action) => {
			state.idVerifiedValue = action.payload;
		},
		toggleIdUploadedCernerCheck: (state) => {
			state.idUploadedCernerCheck = !state.idUploadedCernerCheck;
		},
		setIdUploadedCernerValue: (state, action) => {
			state.idUploadedCernerValue = action.payload;
		},
		toggleInsuranceSubmittedCheck: (state) => {
			state.insuranceSubmittedCheck = !state.insuranceSubmittedCheck;
		},
		setInsuranceSubmittedValue: (state, action) => {
			state.insuranceSubmittedValue = action.payload;
		},
		toggleInsuranceVerifiedCheck: (state) => {
			state.insuranceVerifiedCheck = !state.insuranceVerifiedCheck;
		},
		setInsuranceVerifiedValue: (state, action) => {
			state.insuranceVerifiedValue = action.payload;
		},
		toggleInsuranceUploadedCernerCheck: (state) => {
			state.insuranceUploadedCernerCheck = !state.insuranceUploadedCernerCheck;
		},
		setInsuranceUploadedCernerValue: (state, action) => {
			state.insuranceUploadedCernerValue = action.payload;
		},
		toggleProfileCratedInCernerCheck: (state) => {
			state.profileCratedInCernerCheck = !state.profileCratedInCernerCheck;
		},
		setProfileCratedInCernerValue: (state, action) => {
			state.profileCratedInCernerValue = action.payload;
		},
		toggleRoiCheck: (state) => {
			state.roiCheck = !state.roiCheck;
		},
		setRoiValue: (state, action) => {
			state.roiValue = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPatientChecklist.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(getPatientChecklist.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;

				state.frontDeskStaffCheck = action.payload?.frontDeskStaffCheck;
				state.frontDeskStaffValue = action.payload?.frontDeskStaffValue;
				state.patientNameCheck = action.payload?.patientNameCheck;
				state.patientNameValueFirstName = action.payload?.patientNameValueFirstName;
				state.patientNameValueMiddleName = action.payload?.patientNameValueMiddleName;
				state.patientNameValueLastName = action.payload?.patientNameValueLastName;
				state.dobCheck = action.payload?.dobCheck;
				state.dobValue = action.payload?.dobValue;
				state.dateReferralReceviedCheck = action.payload?.dateReferralReceviedCheck;
				state.dateReferralReceviedValue = action.payload?.dateReferralReceviedValue;
				state.dateReferralApprovedCheck = action.payload?.dateReferralApprovedCheck;
				state.dateReferralApprovedValue = action.payload?.dateReferralApprovedValue;
				state.referralSourceCheck = action.payload?.referralSourceCheck;
				state.referralSourceValue = action.payload?.referralSourceValue;
				state.referralTypeCheck = action.payload?.referralTypeCheck;
				state.referralTypeValue = action.payload?.referralTypeValue;
				state.regFormValue = action.payload?.regFormValue;
				state.regFormCheck = action.payload?.regFormCheck;
				state.noppCheck = action.payload?.noppCheck;
				state.noppValue = action.payload?.noppValue;
				state.communicationConsentCheck = action.payload?.communicationConsentCheck;
				state.communicationConsentValueTelehealth = action.payload?.communicationConsentValueTelehealth;
				state.communicationConsentValueEmail = action.payload?.communicationConsentValueEmail;
				state.registrationPacketUploadCernerCheck = action.payload?.registrationPacketUploadCernerCheck;
				state.registrationPacketUploadCernerValue = action.payload?.registrationPacketUploadCernerValue;
				state.dateChartCreatedCernerCheck = action.payload?.dateChartCreatedCernerCheck;
				state.dateChartCreatedCernerValue = action.payload?.dateChartCreatedCernerValue;
				state.idSubmittedCheck = action.payload?.idSubmittedCheck;
				state.idSubmittedValue = action.payload?.idSubmittedValue;
				state.idVerifiedCheck = action.payload?.idVerifiedCheck;
				state.idVerifiedValue = action.payload?.idVerifiedValue;
				state.idUploadedCernerCheck = action.payload?.idUploadedCernerCheck;
				state.idUploadedCernerValue = action.payload?.idUploadedCernerValue;
				state.insuranceSubmittedCheck = action.payload?.insuranceSubmittedCheck;
				state.insuranceSubmittedValue = action.payload?.insuranceSubmittedValue;
				state.insuranceVerifiedCheck = action.payload?.insuranceVerifiedCheck;
				state.insuranceVerifiedValue = action.payload?.insuranceVerifiedValue;
				state.insuranceUploadedCernerCheck = action.payload?.insuranceUploadedCernerCheck;
				state.insuranceUploadedCernerValue = action.payload?.insuranceUploadedCernerValue;
				state.profileCratedInCernerCheck = action.payload?.profileCratedInCernerCheck;
				state.profileCratedInCernerValue = action.payload?.profileCratedInCernerValue;
				state.roiCheck = action.payload?.roiCheck;
				state.roiValue = action.payload?.roiValue;

				state.loading = false;
			})
			.addCase(getPatientChecklist.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;


				showToast("error", `Get Patient Checklist: ${action.error.message}`);
			})
			.addCase(getPatientChecklistById.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(getPatientChecklistById.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;

				state.frontDeskStaffCheck = action.payload?.frontDeskStaffCheck;
				state.frontDeskStaffValue = action.payload?.frontDeskStaffValue;
				state.patientNameCheck = action.payload?.patientNameCheck;
				state.patientNameValueFirstName = action.payload?.patientNameValueFirstName;
				state.patientNameValueMiddleName = action.payload?.patientNameValueMiddleName;
				state.patientNameValueLastName = action.payload?.patientNameValueLastName;
				state.dobCheck = action.payload?.dobCheck;
				state.dobValue = action.payload?.dobValue;
				state.dateReferralReceviedCheck = action.payload?.dateReferralReceviedCheck;
				state.dateReferralReceviedValue = action.payload?.dateReferralReceviedValue;
				state.dateReferralApprovedCheck = action.payload?.dateReferralApprovedCheck;
				state.dateReferralApprovedValue = action.payload?.dateReferralApprovedValue;
				state.referralSourceCheck = action.payload?.referralSourceCheck;
				state.referralSourceValue = action.payload?.referralSourceValue;
				state.referralTypeCheck = action.payload?.referralTypeCheck;
				state.referralTypeValue = action.payload?.referralTypeValue;
				state.regFormValue = action.payload?.regFormValue;
				state.regFormCheck = action.payload?.regFormCheck;
				state.noppCheck = action.payload?.noppCheck;
				state.noppValue = action.payload?.noppValue;
				state.communicationConsentCheck = action.payload?.communicationConsentCheck;
				state.communicationConsentValueTelehealth = action.payload?.communicationConsentValueTelehealth;
				state.communicationConsentValueEmail = action.payload?.communicationConsentValueEmail;
				state.registrationPacketUploadCernerCheck = action.payload?.registrationPacketUploadCernerCheck;
				state.registrationPacketUploadCernerValue = action.payload?.registrationPacketUploadCernerValue;
				state.dateChartCreatedCernerCheck = action.payload?.dateChartCreatedCernerCheck;
				state.dateChartCreatedCernerValue = action.payload?.dateChartCreatedCernerValue;
				state.idSubmittedCheck = action.payload?.idSubmittedCheck;
				state.idSubmittedValue = action.payload?.idSubmittedValue;
				state.idVerifiedCheck = action.payload?.idVerifiedCheck;
				state.idVerifiedValue = action.payload?.idVerifiedValue;
				state.idUploadedCernerCheck = action.payload?.idUploadedCernerCheck;
				state.idUploadedCernerValue = action.payload?.idUploadedCernerValue;
				state.insuranceSubmittedCheck = action.payload?.insuranceSubmittedCheck;
				state.insuranceSubmittedValue = action.payload?.insuranceSubmittedValue;
				state.insuranceVerifiedCheck = action.payload?.insuranceVerifiedCheck;
				state.insuranceVerifiedValue = action.payload?.insuranceVerifiedValue;
				state.insuranceUploadedCernerCheck = action.payload?.insuranceUploadedCernerCheck;
				state.insuranceUploadedCernerValue = action.payload?.insuranceUploadedCernerValue;
				state.profileCratedInCernerCheck = action.payload?.profileCratedInCernerCheck;
				state.profileCratedInCernerValue = action.payload?.profileCratedInCernerValue;
				state.roiCheck = action.payload?.roiCheck;
				state.roiValue = action.payload?.roiValue;

				state.loading = false;
			})
			.addCase(getPatientChecklistById.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;


				showToast("error", `Get Patient Checklist By Id: ${action.error.message}`);
			})
			.addCase(setPatientChecklist.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError = null;
			})
			.addCase(setPatientChecklist.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError = null;

				showToast("success", "Patient Checklist saved successfully");
			})
			.addCase(setPatientChecklist.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError = action.error;

				showToast("error", `Set Patient Checklist: ${action.error.message}`);
			})
			.addCase(setPatientChecklistById.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError = null;
			})
			.addCase(setPatientChecklistById.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError = null;
			})
			.addCase(setPatientChecklistById.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError = action.error;

				showToast("error", `Set Patient Checklist By Id: ${action.error.message}`);
			});
	},
});

export const {
	setLoading,

	toggleFrontDeskStaffCheck,
	setFrontDeskStaffValue,
	togglePatientNameCheck,
	setPatientNameValueFirstName,
	setPatientNameValueMiddleName,
	setPatientNameValueLastName,
	toggleDobCheck,
	setDobValue,
	toggleDateReferralReceviedCheck,
	setDateReferralReceviedValue,
	toggleDateReferralApprovedCheck,
	setDateReferralApprovedValue,
	toggleReferralSourceCheck,
	setReferralSourceValue,
	toggleReferralTypeCheck,
	setReferralTypeValue,
	toggleRegFormCheck,
	setRegFormValue,
	toggleNoppCheck,
	setNoppValue,
	toggleCommunicationConsentCheck,
	toggleCommunicationConsentValueTelehealth,
	toggleCommunicationConsentValueEmail,
	toggleDateChartCreatedCernerCheck,
	setDateChartCreatedCernerValue,
	toggleRegistrationPacketUploadCernerCheck,
	setRegistrationPacketUploadCernerValue,
	toggleIdSubmittedCheck,
	setIdSubmittedValue,
	toggleIdVerifiedCheck,
	setIdVerifiedValue,
	toggleIdUploadedCernerCheck,
	setIdUploadedCernerValue,
	toggleInsuranceSubmittedCheck,
	setInsuranceSubmittedValue,
	toggleInsuranceVerifiedCheck,
	setInsuranceVerifiedValue,
	toggleInsuranceUploadedCernerCheck,
	setInsuranceUploadedCernerValue,
	toggleProfileCratedInCernerCheck,
	setProfileCratedInCernerValue,
	toggleRoiCheck,
	setRoiValue,
} = patientChecklist.actions;

export default patientChecklist.reducer;