import { Wrapper } from '../../../../../components/Main/Main.style';
import { GRID_MD, GRID_SM, GRID_XS, MAX_WIDTH, NEXT_KIN, PHONE_MASK, ZIP_MASK } from '../../../../../utils/constants';
import { onFocusInTextboxMask, onFocusOutTextboxMask, onKeyDownHandle } from '../../../../../utils/helper';
import { NAME_RULE } from '../../../../../utils/rules';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import CheckBox from 'devextreme-react/check-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	EmailRule,
	PatternRule,
} from 'devextreme-react/validator';

export const NextOfKin = ({
	loading,
	states,
	checkbox,
	nextOfKin,
	relationship,
	address,
	city,
	state,
	zip,
	phone,
	email,

	onToggleCheckbox,
	onChangeNextOfKin,
	onChangeRelationship,
	onChangeAddress,
	onChangeCity,
	onChangeState,
	onChangeZip,
	onChangePhone,
	onChangeEmail,

	onSaveNextOfKin,
	onSaveAndContinueNextOfKin,
}) => {
	return (
		<Wrapper id={NEXT_KIN} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${NEXT_KIN}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Same as Emergency Contact">
							<>
								<CheckBox
									text="Same as Emergency Contact"
									value={checkbox}
									onValueChanged={onToggleCheckbox} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Next of Kin *">
							<>
								<TextBox
									label="Next of Kin *"
									value={nextOfKin}
									onValueChanged={onChangeNextOfKin}
									disabled={checkbox}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-nextOfKin' }}
								>
									<Validator validationGroup={NEXT_KIN}>
										<RequiredRule
											message="Next of Kin is required"
										/>
										<PatternRule
											message="Do not use digits in the Next of Kin"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Relationship">
							<>
								<TextBox
									label="Relationship"
									value={relationship}
									onValueChanged={onChangeRelationship}
									disabled={checkbox}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-relationship' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Address">
							<>
								<TextBox
									label="Address"
									value={address}
									onValueChanged={onChangeAddress}
									disabled={checkbox}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="City">
							<>
								<TextBox
									label="City"
									value={city}
									onValueChanged={onChangeCity}
									disabled={checkbox}
									maxLength={20}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-city' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="State">
							<>
								<SelectBox
									label="State"
									defaultValue={state}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={onChangeState}
									disabled={checkbox}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-state' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Zip">
							<>
								<TextBox
									label="Zip"
									value={zip}
									onValueChanged={onChangeZip}
									disabled={checkbox}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									onFocusIn={onFocusInTextboxMask}
									onFocusOut={onFocusOutTextboxMask}
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-zip' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Phone *">
							<>
								<TextBox
									label="Phone *"
									value={phone}
									onValueChanged={onChangePhone}
									disabled={checkbox}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									onFocusIn={onFocusInTextboxMask}
									onFocusOut={onFocusOutTextboxMask}
									mask={PHONE_MASK}
									maskChar="."
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }}>
									<Validator validationGroup={NEXT_KIN}>
										<RequiredRule
											message="Phone is required"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Email">
							<>
								<TextBox
									label="Email"
									value={email}
									onValueChanged={onChangeEmail}
									disabled={checkbox}
									maxLength={64}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-email' }}>
									<Validator validationGroup={NEXT_KIN}>
										<EmailRule
											message="Email is invalid"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right', gap: '10px', display: 'flex', justifyContent: 'flex-end' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="outlined"
							validationGroup={NEXT_KIN}
							onClick={onSaveNextOfKin} />
						<Button
							text="Save and continue"
							type="default"
							stylingMode="contained"
							validationGroup={NEXT_KIN}
							onClick={onSaveAndContinueNextOfKin} />
					</Grid>
				</Grid>
			}
		</Wrapper>
	);
};