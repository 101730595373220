
import TabPanel from 'devextreme-react/tab-panel';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    margin: '30px 0px 0px 40px',
    '@media (max-width: 768px)': {
        margin: '10px 0 0 20px',
    },
}));

export const TitleWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '20px',
    '@media (max-width: 425px)': {
        marginBottom: 'unset',
    },
}));

export const Title = styled('h1')(() => ({
    color: 'var(--dark-blue-color)',
    fontFamily: 'Montserrat',
    userSelect: 'none',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '27px',
    margin: '0px',
    order: '1',
    '@media (max-width: 425px)': {
        fontSize: '16px',
        lineHeight: '20px',
    },
}));

export const TabPanelWrapper = styled('div')(() => ({
    display: 'flex',
    '@media (max-width: 425px)': {
        paddingTop: '8px',
    },
}));

export const TabPanelStyled = styled(TabPanel)(({ open }) => ({
    width: "100%",
    '@media (max-width: 660px)': {
        width: "calc(100% - 80px)",
    },
    '@media (max-width: 920px)': {
        ...(open && {
            display: "none",
        }),
    },
}));