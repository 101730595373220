import { FETCH_STATUSES } from '../../../utils/constants';
import { getInvitedList, deactivateInvited, getInviteLinkforCopy } from './middlewares';
import { createSlice } from '@reduxjs/toolkit';
import { showToast } from '../../../screens/Main/MainContainer';

const initialState = {
	value: [],
	getStatus: FETCH_STATUSES.IDLE,
	getError: null,
	loading: true,
	loadingDeactivate: false,
	activeValue: [],
	activeInviteeId: '',
	statusBtnInvite: false,
	activefilterValue: [],
	currentFilterState: false,
	selectedFilterValue: 0,
};

const invitedList = createSlice({
	name: 'invitedList',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
			return state;
		},
		setActiveInviteeId: (state, action) => {
			state.activeInviteeId = action.payload;
			return state;
		},
		setFilteredInvitations: (state, action) => {
			state.value = action.payload;
			return state;
		},
		setActivefilterValue: (state, action) => {
			state.activefilterValue = action.payload;
			return state;
		},
		setCurrentFilterState: (state, action) => {
			state.currentFilterState = action.payload;
			return state;
		},
		setSelectedFilterValue: (state, action) => {
			state.selectedFilterValue = action.payload;
			return state;
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(getInvitedList.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(getInvitedList.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;

				const compare = (a, b) => new Date(b?.dateAdded) - new Date(a?.dateAdded);

				const inviteList = action.payload.filter(item => item.dateAdded).sort(compare);

				state.value = inviteList;
				state.activeValue = action.payload.filter((invitee) => invitee.id === state.activeInviteeId)[0];
				state.loading = false;
				state.loadingDeactivate = false;
			})
			.addCase(getInvitedList.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;
				showToast('error', `Get Invite List: ${action.error.message}`);
			})
			.addCase(deactivateInvited.pending, (state) => {
				state.invateStatus = FETCH_STATUSES.REQUEST;
				state.invateError = null;
				state.loadingDeactivate = true;
			})
			.addCase(deactivateInvited.fulfilled, (state) => {
				state.sendStatus = FETCH_STATUSES.SUCCESS;
				showToast('success', 'Invite deleted successfully');
			})
			.addCase(deactivateInvited.rejected, (state, action) => {
				state.invateStatus = FETCH_STATUSES.FAILURE;
				state.invateError = action.error;
				state.loadingDeactivate = false;
				showToast('error', 'Unable to delete invite');
			})
			.addCase(getInviteLinkforCopy.pending, (state) => {
				state.invateStatus = FETCH_STATUSES.REQUEST;
				state.statusBtnInvite = true;
			})
			.addCase(getInviteLinkforCopy.fulfilled, (state, action) => {
				state.sendStatus = FETCH_STATUSES.SUCCESS;
				state.statusBtnInvite = false;
				showToast('success', 'Copying the invite link to the clipboard successfully completed');
			})
			.addCase(getInviteLinkforCopy.rejected, (state, action) => {
				state.invateStatus = FETCH_STATUSES.FAILURE;
				state.statusBtnInvite = false;
				showToast('error', 'Error copying invite link');
			});
	},
});

export const {
	setLoading,
	setActiveValue,
	setActiveInviteeId,
	setFilteredInvitations,
	setActivefilterValue,
	setCurrentFilterState,
	setSelectedFilterValue,
} = invitedList.actions;

export default invitedList.reducer;