import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { getPatientList, deactivatePatient, initPatientList } from '../../../store/users/patientsList/middlewares';
import { setLoading } from '../../../store/users/patientsList/slice';
import { selectLoading, selectLoadingDeactivate, selectPatientList } from "../../../store/users/patientsList/selectors";
import { PatientsList } from './PatientsList';
import { setShowInvite } from "../../../store/users/generationInvites/slice";

import { Dialog, DialogTitle, DialogContent, DialogContentRow, DialogText, DialogActions } from '../../../components/Dialog/Dialog.style';
import Button from 'devextreme-react/button';
import LoadPanel from 'devextreme-react/load-panel';

export const PatientsListContainer = () => {
	const [open, setOpen] = useState(false);
  const [patients, setPatients] = useState([]);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const loading = useSelector(selectLoading);
  const loadingDeactivate = useSelector(selectLoadingDeactivate);
	const dataSource = useSelector(selectPatientList);

	useEffect(() => {
		dispatch(setLoading(true));
		dispatch(getPatientList());
	}, [dispatch]);

	const showProfile = (id) => {
		navigate(`profile/${id}`);
	}

	const showInvite = () => {
		dispatch(setShowInvite(true));
	}

	const onClickDeactivateInvited = (emails) => {
    dispatch(deactivatePatient(emails)).then(({ payload }) => {
      if (payload.response.ok) {
        dispatch(initPatientList()).then(() => {
          setOpen(false);
          dispatch(getPatientList());
        });
      }
    });
  };

	const onOpenDialog = (invited) => {
		const emails = invited.map((el) => el.email).join(', ');
		setPatients(emails);
		setOpen(true);
	};

	const onCloseDialog = () => setOpen(false);

	return (
    <>
      <Dialog open={open} onClose={onCloseDialog}>
        <DialogTitle>Delete invite</DialogTitle>
        <DialogContent>
          <DialogContentRow>
            {loadingDeactivate ? (
              <LoadPanel visible={true} />
            ) : (
              <DialogText>
                Delete patient user(s) with the following email(s): <b>{patients}</b>
              </DialogText>
            )}
          </DialogContentRow>
        </DialogContent>
        <DialogActions>
          {!loadingDeactivate ? (
            <>
              <Button
                text="Ok"
                type="default"
                stylingMode="contained"
                onClick={() => onClickDeactivateInvited(patients)}
              />
              <Button text="Cancel" type="default" stylingMode="outlined" onClick={onCloseDialog} />
            </>
          ) : (
            ''
          )}
        </DialogActions>
      </Dialog>

      <PatientsList
        loading={loading}
        dataSource={dataSource}
        showProfile={showProfile}
        showInvite={showInvite}
        onOpenDialog={onOpenDialog}
      />
    </>
	);
}